<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import LearnGrid from "@/components/learn/LearnGrid.vue";
import LearnPath from "@/components/learn/LearnPath.vue";
import { useLearnStore } from "@/stores/learnStore";
import { useHead } from "@vueuse/head";

useHead({
  title: "Learn | Two Bros Inferencing | Enhance your apps with AI",
  meta: [
    {
      name: "description",
      content:
        "Learn more about enhancing your apps with AI. Choose a learning path right for your knowledge level.",
    },
    {
      property: "og:description",
      content:
        "Learn more about enhancing your apps with AI. Choose a learning path right for your knowledge level.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});

const route = useRoute();
const router = useRouter();
const learnStore = useLearnStore();
const selectedPath = ref(route.params.pathId);
const currentSlide = ref(parseInt(route.query.slide || 1));

// Fetch courses when the component mounts
onMounted(async () => {
  await learnStore.fetchCourses();
  if (route.params.pathId) {
    selectPath(route.params.pathId);
    currentSlide.value = parseInt(route.query.slide || 1);
  }
});

// Updated computed property for learning paths
const learningPaths = computed(() => {
  return learnStore.courses.map((course) => ({
    id: course.courseTitle.toLowerCase().replace(/\s+/g, "-"), // Convert title to a URL-friendly ID
    title: course.courseTitle,
    description: `${course.courseDescription}`,
    image: course.lessons[0]?.image, // Using the first lesson's image for the course
  }));
});

const selectPath = (pathId) => {
  selectedPath.value = pathId;
  const slideNumber = route.query.slide ? parseInt(route.query.slide, 10) : 1;
  router.push({
    name: "LearnPath",
    params: { pathId: pathId },
    query: { slide: slideNumber },
  });
};
</script>

<template>
  <div
    class="mx-auto py-4 px-8 w-full mt-20 md:mt-42 overflow-x-hidden max-w-7xl"
    style="min-height: 100vh"
  >
    <div class="p-4 w-full flex flex-col">
      <div class="text-center pb-4">
        <h1 class="hidden lg:block text-4xl lg:text-6xl text-base-content">
          LEARN
        </h1>
        <h1
          v-if="!selectedPath"
          class="block lg:hidden text-4xl lg:text-6xl text-base-content"
        >
          Learn
        </h1>
        <p v-if="!selectedPath" class="text-xl text-secondary-focus">
          Enhance your apps with AI
        </p>
      </div>
      <LearnGrid
        v-if="!selectedPath"
        :paths="learningPaths"
        @selectPath="selectPath"
      />
      <LearnPath
        v-if="selectedPath"
        :pathId="selectedPath"
        :currentSlide="currentSlide"
        class="bg-base-200 p-5 pt-10 md:p-10 xl:p-12 rounded-lg shadow-lg w-85 md:w-120 xl:w-160"
      />
    </div>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
