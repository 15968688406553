<script setup>
import { useHead } from "@vueuse/head";
import LoginModal from "@/components/login/LoginModal.vue";

useHead({
  title: "Register | Two Bros Inferencing",
  meta: [
    {
      name: "description",
      content: "Register for a new account.",
    },
    {
      property: "og:description",
      content: "Register for a new account.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});
</script>

<template>
  <div class="hero mx-auto p-4 w-full overflow-x-hidden">
    <div class="hero-content w-full flex flex-col mt-40">
      <div class="w-68 sm:w-80 lg:w-116">
        <LoginModal />
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
