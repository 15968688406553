<!-- HomePage.vue | @/pages/HomePage.vue -->
<script setup>
import { onMounted } from "vue";
import { useHead } from "@vueuse/head";
import { useSessionStore } from "@/stores/session";

useHead({
  title:
    "Two Bros Inferencing | Simple And Intuitive Developer Platform for LLM Powered Apps",
  meta: [
    {
      name: "description",
      content:
        "We provide easy to use APIs for LLM powered apps. Our platform is designed to be simple and intuitive, allowing you to focus on building your product.",
    },
    {
      property: "og:description",
      content:
        "We provide easy to use APIs for LLM powered apps. Our platform is designed to be simple and intuitive, allowing you to focus on building your product.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
  script: [
    {
      type: "application/ld+json",
      json: {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Two Bros Inferencing",
        url: "https://twobrosinferencing.com",
        logo: "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
        sameAs: ["https://www.twitter.com/TwoBrosInf"],
      },
    },
  ],
});

const sessionStore = useSessionStore();
onMounted(() => {
  if (sessionStore.firstLoad) {
    console.log("Welcome to Two Bros Inferencing!");
    sessionStore.firstLoad = false; // Set firstLoad to false after the first load
  }
});
</script>

<template>
  <div
    :class="[
      'relative w-full h-screen flex items-center justify-center overflow-x-hidden',
      sessionStore.theme === 'cedrosdark' ? 'dark-gradient' : 'light-gradient',
    ]"
  >
    <div class="relative container mx-auto max-w-screen-xl text-center">
      <h1
        class="text-4xl lg:text-6xl max-w-2xl mx-auto mb-8 lg:mb-12 text-base-content adjust-for-view-height"
        style="line-height: 1.2"
      >
        add ai To your apps with ease
      </h1>
      <h3
        class="text-lg lg:text-2xl mb-12 max-w-lg mx-auto text-secondary-focus"
      >
        Our platform is designed to be simple and intuitive, allowing you to
        focus on building your product.
      </h3>
      <router-link
        to="/login"
        class="bg-primary border-primary border-2 text-base-content px-6 py-2 rounded-full mr-2 hover:bg-primary-focus hover:border-primary-focus custom-button"
      >
        Sign Up
      </router-link>
      <router-link
        to="/docs"
        class="border-base-content border-2 text-base-content px-6 py-2 rounded-full hover:bg-base-300 hover:bg-opacity-30 custom-button"
      >
        Docs
      </router-link>
      <div class="emptySpace mt-0 lg:mt-32"></div>
    </div>
  </div>
</template>

<style scoped>
.dark-gradient {
}

/* Light Theme Gradient */
.light-gradient {
}
/* Custom class to target specific screen sizes */
@media (min-width: 992px) and (max-height: 1000px) {
  .adjust-for-view-height {
    margin-top: 8rem; /* Adjust this value as needed */
  }
}
</style>
