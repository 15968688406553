<!-- FooterBar.vue | @/components/navigation/FooterBar.vue -->
<script setup>
// import { useCoinsStore } from "@/stores/coins";
// import axios from "axios";
import ThemeSwitcher from "./ThemeSwitcher.vue";

// Initialize the coins store
// const coinsStore = useCoinsStore();

// const stats = reactive({
//   trueTPS: 420,
//   solPrice: 0,
//   dailyChange: 0,
//   daysSinceOutage: 0,
// });

// const fetchTrueTPS = async () => {
//   try {
//     // Update the request to use your backend endpoint and include the API key
//     const { data } = await axios.get(
//       `${process.env.VUE_APP_BACKEND_API_URL}/true-tps`,
//       {
//         headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
//       }
//     );
//     console.log(JSON.stringify(data));

//     // Assuming the backend returns the true TPS directly as a number
//     // If the structure is different, adjust the path to the true TPS value accordingly
//     stats.trueTPS = data.trueTPS;
//   } catch (error) {
//     console.error("Error fetching True TPS:", error);
//   }
// };

// Computed property to get the SOL price and daily change
// const solData = computed(() => {
//   const solCoin = coinsStore.coins.find((coin) => coin.symbol === "SOL");
//   if (solCoin) {
//     return {
//       price: solCoin.currentPriceCoinGecko,
//       dailyChange: solCoin.change24h,
//     };
//   }
//   return { price: 0, dailyChange: 0 };
// });

// const calculateDaysSinceOutage = () => {
//   const outageDate = new Date("2024-02-07");
//   const currentDate = new Date();
//   const differenceInTime = currentDate.getTime() - outageDate.getTime();
//   const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
//   stats.daysSinceOutage = differenceInDays;
// };

// Get the year for the footer copyright date
const currentYear = new Date().getFullYear();
</script>

<template>
  <div
    class="max-w-full mb-0 pb-0 bg-base-300 border-neutral border-t-2 z-100 text-base-content"
  >
    <!-- <coingecko-coin-price-marquee-widget
      coin-ids="bitcoin,ethereum,solana,bonk,genesysgo-shadow,render-token,helium,pyth-network,hxro,guacamole,jito-staked-sol,marinade,orca,raydium,mango-markets"
      currency="usd"
      background-color="#292524"
      font-color="#d6d3d1"
      locale="en"
    ></coingecko-coin-price-marquee-widget> -->
    <!-- <div
      id="coinmarketcap-widget-marquee"
      coins="1,1027,825,3408,5426,23095,16868,5690,5665,28177,3748,24935,22533,13803,11165,8526,11171"
      currency="USD"
      theme="dark"
      transparent="true"
      show-symbol-logo="true"
    ></div> -->
    <footer
      class="mx-auto mt-1 mb-6 md:mb-2 md:p-4 bg-base-300 block xl:flex flex-col xl:flex-row md:min-h-[10rem]"
    >
      <!-- Left Column for Social Links -->
      <div
        class="flex flex-col justify-end items-start pl-1 md:pl-2 mb-4 md:mb-0 md:flex-grow-0 md:flex-shrink-0 brand-column"
      >
        <!-- Stats section start -->
        <div class="mt-auto pl-4">
          <!-- Container for Social Links and Copyright -->
          <div class="mt-6">
            <h4 class="md:hidden text-base-content">Socials</h4>
            <!-- Twitter -->
            <a
              href="https://twitter.com/TwoBrosInf"
              rel="noopener"
              target="_blank"
              class="px-1"
            >
              <font-awesome-icon
                :icon="['fab', 'square-x-twitter']"
                class="w-8 h-8 hover:text-primary-focus"
              />
            </a>
            <!-- Instagram -->
            <!-- <a
              href="https://instagram.com/"
              target="_blank"
              class="px-1"
            >
              <font-awesome-icon
                :icon="['fab', 'instagram']"
                class="w-8 h-8 hover:text-primary-focus"
              />
            </a> -->
            <!-- Github  -->
            <a
              href="https://github.com/TwoBrosInf"
              target="_blank"
              class="px-1"
            >
              <font-awesome-icon
                :icon="['fab', 'square-github']"
                class="w-8 h-8 hover:text-primary-focus"
              />
            </a>
          </div>
          <!-- Desktop Copyright -->
          <div class="hidden xl:block mt-6 pr-34">
            <p class="text-base-content">
              By using our platform, you agree to our
              <router-link class="hover:text-primary-focus" to="/terms"
                >terms of service.</router-link
              >
            </p>
          </div>
          <div class="hidden xl:block mt-6">
            ©{{ currentYear }} Two Bros Inferencing. All Rights Reserved.
          </div>
        </div>
      </div>

      <!-- Right Column for Menus -->

      <div
        class="flex-1 grid grid-cols-1 md:grid-cols-4 gap-4 pl-6 xl:pl-0 mt-8 xl:mt-4"
      >
        <!-- Menu 1: Learn -->
        <div class="block text-left">
          <h4 class="text-base-content">info</h4>
          <ul>
            <li>
              <router-link to="/about">About</router-link>
            </li>
            <li>
              <router-link to="/faq">FAQ</router-link>
            </li>
          </ul>
        </div>

        <!-- Menu 2: Info -->
        <div class="block text-left mt-4 md:mt-0">
          <h4 class="text-base-content">legal</h4>
          <ul>
            <li>
              <router-link to="/privacy-policy"> Privacy</router-link>
            </li>
            <li><router-link to="/terms">Terms</router-link></li>
          </ul>
        </div>

        <!-- Menu 3: Account -->
        <div class="block text-left mt-4 md:mt-0">
          <h4 class="text-base-content">account</h4>
          <ul>
            <li><router-link to="/login">Login</router-link></li>
            <li><router-link to="/register">Register</router-link></li>
          </ul>
        </div>

        <!-- Menu 4: Tools -->
        <div class="block text-left pr-2 mt-4 md:mt-0">
          <h4 class="text-base-content">dev</h4>
          <ul>
            <li><router-link to="/docs">Docs</router-link></li>
            <li>
              <router-link to="/#">NPM</router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- Mobile Copyright -->
      <div class="block xl:hidden mt-12 px-6 w-full">
        <p class="text-base-content">
          This website's content is for informational purposes only and not
          intended as financial advice; please refer to our full
          <a class="hover:text-primary-focus" href="/disclaimer" rel="noopener">
            Disclaimer</a
          >
          for details.
        </p>
      </div>
      <div class="block xl:hidden mt-2 px-6 mb-2 text-sm w-full">
        ©{{ currentYear }} Two Bros Inferencing. All Rights Reserved.
      </div>
      <ThemeSwitcher />
    </footer>
  </div>
</template>

<style scoped>
h4 {
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-family: BNBinder;
  text-transform: uppercase;
}
.brand-column {
  width: 50%;
  min-width: 200px;
}

li:hover {
  @apply text-primary-focus;
}
</style>
