<template>
  <div class="min-h-screen py-12 px-4 sm:px-6 lg:px-8 text-base-content">
    <div class="max-w-3xl mx-auto">
      <h1
        class="text-4xl lg:text-6xl font-bold max-w-2xl mx-auto mt-20 md:mt-42 mb-12 text-base-content text-center"
      >
        WELCOME
      </h1>
      <h3 class="text-2xl font-semibold text-center mb-8">Things to do here</h3>

      <div class="space-y-6">
        <a
          v-for="(option, index) in options"
          :key="index"
          :href="option.link"
          rel="noopener noreferrer"
          class="bg-base-100 shadow-md rounded-lg p-6 flex items-start space-x-4 transition duration-300 ease-in-out transform hover:scale-102 hover:shadow-lg"
        >
          <div class="flex-shrink-0">
            <font-awesome-icon
              :icon="option.icon"
              class="h-8 w-8 text-primary"
            />
          </div>
          <div>
            <h3 class="text-lg font-medium">
              {{ option.title }}
            </h3>
            <p class="mt-1">{{ option.description }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomePage",
  data() {
    return {
      options: [
        {
          title: "Chat with AI",
          description:
            "Chat with our AI chatbot to test our inferencing capabilities.",
          icon: ["fas", "comment"],
          link: "/dashboard/chat",
        },
        {
          title: "Get Your API Key",
          description: "Get your API key to start using our API.",
          icon: ["fas", "key"],
          link: "/dashboard/apikey",
        },
        {
          title: "Set Up Usage Alerts",
          description:
            "Receive notifications when your usage reaches your set limits.",
          icon: ["fas", "bell"],
          link: "/dashboard/alerts",
        },
        {
          title: "Get Support",
          description: "Contact us for any questions or support you need.",
          icon: ["fas", "envelope"],
          link: "/support",
        },
        {
          title: "Refer Friends",
          description: "Earn 3% of your referrals' subscription costs.",
          icon: ["fas", "user-plus"],
          link: "/dashboard/referrals",
        },
      ],
    };
  },
};
</script>
