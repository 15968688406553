<template>
  <div
    v-if="registrationStep === 'start'"
    class="p-8 bg-base-200 text-base-content rounded-lg shadow-lg mx-auto h-100 border border-neutral"
  >
    <h2 class="text-2xl font-bold text-center text-base-content mb-10 mt-2">
      Login or Register
    </h2>
    <div class="flex justify-around mb-12">
      <div class="relative group" @click="handleEmailLogin">
        <div
          class="w-16 h-16 bg-neutral-focus rounded-full flex items-center justify-center cursor-pointer text-2xl text-base-content"
        >
          <font-awesome-icon icon="fa-solid fa-envelope" />
        </div>
        <div
          class="absolute w-16 text-center text-base-content mt-2 opacity-0 group-hover:opacity-100 transition-opacity"
        >
          Email
        </div>
      </div>
      <div class="relative group" @click="handleGoogleLogin">
        <div
          class="w-16 h-16 bg-neutral-focus rounded-full flex items-center justify-center cursor-pointer text-2xl text-base-content"
        >
          <font-awesome-icon icon="fa-brands fa-google" />
        </div>
        <div
          class="absolute w-16 text-center text-base-content mt-2 opacity-0 group-hover:opacity-100 transition-opacity"
        >
          Google
        </div>
      </div>
      <div class="relative group" @click="handleSolanaLogin">
        <div
          class="w-16 h-16 bg-neutral-focus rounded-full flex items-center justify-center cursor-pointer text-2xl text-base-content"
        >
          <font-awesome-icon icon="fa-solid fa-shield" />
        </div>
        <div
          class="absolute w-16 text-center text-base-content mt-2 opacity-0 group-hover:opacity-100 transition-opacity"
        >
          Wallet
        </div>
      </div>
    </div>
    <p v-if="showStartMessage" class="text-center text-base-content">
      Select a login method above.
    </p>
    <form v-if="showEmailFields" @submit.prevent="handleSubmit">
      <div class="mb-4">
        <input
          type="email"
          v-model="email"
          class="w-full p-2 border border-neutral bg-base-100 rounded"
          placeholder="Email"
          required
        />
      </div>
      <div class="mb-4">
        <input
          type="password"
          v-model="password"
          class="w-full p-2 border border-neutral bg-base-100 rounded"
          placeholder="Password"
          required
        />
      </div>
      <div class="flex justify-center w-full">
        <button
          type="submit"
          class="w-full px-4 py-2 text-base-content rounded transition duration-300"
          :class="{
            'bg-primary hover:bg-primary-focus': !isSubmitDisabled,
            'bg-neutral-focus cursor-not-allowed': isSubmitDisabled,
          }"
          :disabled="isSubmitDisabled"
        >
          {{ submitButtonText }}
        </button>
      </div>
    </form>
    <div v-if="showResetPasswordForm" class="mt-4">
      <p>Reset your password:</p>
      <input
        type="email"
        v-model="resetEmail"
        class="w-full p-2 border border-neutral bg-base-100 rounded"
        placeholder="Enter your email"
        required
      />
      <button
        @click="resetPassword"
        class="w-full mt-8 px-4 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
      >
        Reset Password
      </button>
    </div>
    <div
      v-if="showWalletMessage"
      class="mt-8 text-center text-base-content w-10/12 mx-auto"
    >
      <p v-if="!connected">Connect a Solana wallet:</p>
      <p v-if="connected">Please sign the message to login.</p>
      <wallet-multi-button ref="walletButtonRef" dark></wallet-multi-button>
      <button
        v-if="showResendButton"
        @click="signSolanaMessage"
        class="mt-4 px-4 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-300 w-full"
      >
        Resend Message
      </button>
    </div>
    <p v-if="message" class="mt-4 text-center text-red-500">{{ message }}</p>
  </div>
  <!-- Forgot Password Link -->
  <div
    v-if="showEmailFields && registrationStep === 'start'"
    class="mt-2 text-center"
  >
    <a
      href="#"
      @click.prevent="showForgotPassword"
      class="text-sm text-base-100 hover:text-primary-focus"
    >
      Forgot Password?
    </a>
  </div>
  <div
    v-if="!showEmailFields || !registrationStep === 'start'"
    class="mt-0 pt-0 h-8"
  ></div>
  <!-- Next Steps -->
  <div v-if="registrationStep === 'complete-info'">
    {{ console.log("isSolanaUser:", isSolanaUser) }}
    <CompleteInfo
      :email="email"
      :name="name"
      :requireBoth="isSolanaUser"
      :showEmailField="isSolanaUser"
      @info-completed="handleInfoCompleted"
    />
  </div>
  <div v-if="registrationStep === 'add-password'">
    <AddPassword
      @password-set="handlePasswordSet"
      @skip-password="handleSkipPassword"
    />
  </div>
  <div v-if="registrationStep === 'wallet-type'">
    <WalletTypeSelection
      :isSolanaUser="isSolanaUser"
      :existingPublicKey="solanaPublicKey"
      @wallet-type-selected="handleWalletTypeSelected"
    />
  </div>
  <div v-if="registrationStep === 'verify-email'">
    <VerifyEmail
      :email="email"
      @verification-completed="handleVerificationCompleted"
    />
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import eventBus from "@/eventBus";
import { WalletMultiButton, useWallet } from "solana-wallets-vue";
import { useSessionStore } from "@/stores/session";
import CompleteInfo from "./CompleteInfo.vue";
import VerifyEmail from "./VerifyEmail.vue";
import AddPassword from "./AddPassword.vue";
import WalletTypeSelection from "./WalletTypeSelection.vue";

export default {
  components: {
    WalletMultiButton,
    CompleteInfo,
    VerifyEmail,
    AddPassword,
    WalletTypeSelection,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const showStartMessage = ref(true);
    const showEmailFields = ref(false);
    const showWalletMessage = ref(false);
    const showResendButton = ref(false);
    const email = ref("");
    const password = ref("");
    const message = ref("");
    const showResetPasswordForm = ref(false);
    const resetEmail = ref("");
    const registrationStep = ref("start");
    const name = ref("");
    const userVerified = ref(false);
    const isSolanaUser = ref(false);
    const isGoogleUser = ref(false);
    const hasEnteredEmail = ref(false);
    const completeInfoError = ref("");

    onMounted(() => {
      const newUser = route.query.newUser;
      if (newUser === "true") {
        isGoogleUser.value = true;
        registrationStep.value = "add-password";
      }
    });

    const { publicKey, connected, signMessage } = useWallet();
    const solanaPublicKey = computed(() =>
      publicKey.value ? publicKey.value.toString() : ""
    );

    const handleEmailLogin = () => {
      showStartMessage.value = false;
      message.value = "";
      showWalletMessage.value = false;
      showEmailFields.value = true;
      showResetPasswordForm.value = false;
      isGoogleUser.value = false;
      isSolanaUser.value = false;
    };

    const handleGoogleLogin = () => {
      showStartMessage.value = false;
      showWalletMessage.value = false;
      showResetPasswordForm.value = false;
      showEmailFields.value = false;
      message.value = "";
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      const redirectUri = encodeURIComponent(window.location.href);
      window.location.href = `${baseUrl}/auth/login?redirect=${redirectUri}`;
      isGoogleUser.value = true;
      isSolanaUser.value = false;
    };

    const handleSolanaLogin = async () => {
      showStartMessage.value = false;
      showEmailFields.value = false;
      showResetPasswordForm.value = false;
      showWalletMessage.value = true;
      isGoogleUser.value = false;
      isSolanaUser.value = true;

      if (connected.value) {
        await signSolanaMessage();
      }
    };

    const showForgotPassword = () => {
      showResetPasswordForm.value = true;
      showEmailFields.value = false;
    };

    const signSolanaMessage = async () => {
      showResendButton.value = false;
      console.log("Starting message signing...");
      const messageToSign = new TextEncoder().encode(
        "Login by verifying ownership of this wallet. There is no network fee for this action."
      );

      const resendTimeout = setTimeout(() => {
        if (connected.value) {
          showResendButton.value = true;
          console.log("Show resend button");
        }
      }, 10000); // 10 seconds

      try {
        const signedMessage = await signMessage.value(messageToSign);
        clearTimeout(resendTimeout);
        const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
        const response = await fetch(`${baseUrl}/users/solana-login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            publicKey: solanaPublicKey.value.toString(),
            signature: btoa(String.fromCharCode.apply(null, signedMessage)),
          }),
        });

        if (response.ok) {
          const user = await response.json();
          console.log("Solana login successful, user data:", user);

          if (user.isNewUser) {
            // If it's a new user, move to the complete info step
            isSolanaUser.value = true;
            registrationStep.value = "complete-info";
          } else if (!user.verified && user.email) {
            // If the user has an email but is not verified, move to verify email step
            email.value = user.email;
            registrationStep.value = "verify-email";
          } else {
            // User is already registered and verified (or has no email), proceed with login
            eventBus.emit("login", user);
            // redirect them to the dashboard page
            router.push("/dashboard");
          }
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }
      } catch (error) {
        message.value = `Solana login failed: ${error.message}`;
        console.error("Error signing message:", error.message);
        if (
          error.message.includes("Approval Denied") ||
          error.message.includes("Plugin Closed")
        ) {
          message.value = "";
          showResendButton.value = true;
        } else if (
          error.message.includes("signMessage.value is not a function")
        ) {
          showResendButton.value = false;
          message.value = "Please connect a wallet.";
        }
      }
    };

    watch(connected, async (newConnected) => {
      if (newConnected && showWalletMessage.value) {
        await signSolanaMessage();
      }
    });

    const handleSubmit = async () => {
      console.log("Handling form submit");
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      try {
        // First, check if the user exists
        const checkUserResponse = await fetch(`${baseUrl}/users/check`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ email: email.value }),
        });

        if (checkUserResponse.ok) {
          // User exists, attempt to log in
          await login();
        } else {
          // User doesn't exist, attempt to register
          await register();
        }
      } catch (error) {
        console.error("Error:", error);
        message.value = `Error: ${error.message}`;
      }
    };

    const register = async () => {
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      try {
        const response = await fetch(`${baseUrl}/users/register`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email: email.value,
            password: password.value,
          }),
        });

        if (response.ok) {
          const user = await response.json();
          console.log("Initial registration successful, user data:", user);
          isSolanaUser.value = false; // E
          registrationStep.value = "complete-info";
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }
      } catch (error) {
        message.value = `Registration failed: ${error.message}`;
      }
    };

    const handleInfoCompleted = async (userInfo) => {
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      try {
        if (userInfo.skipped) {
          console.log("User skipped entering information");
          if (isSolanaUser.value || isGoogleUser.value) {
            registrationStep.value = "add-password";
          } else if (!userInfo.verified && userInfo.email) {
            registrationStep.value = "verify-email";
          } else {
            eventBus.emit("login", userInfo);
            message.value = "Registration successful!";
            router.push("/welcome");
          }
          return { success: true };
        }

        // Only check email if it's provided
        if (userInfo.email) {
          console.log("Checking email...");
          const checkEmailResponse = await fetch(
            `${baseUrl}/users/check-email`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
              body: JSON.stringify({ email: userInfo.email }),
            }
          );

          if (checkEmailResponse.status === 409) {
            console.log("Email already exists. STATUS 409");
            completeInfoError.value =
              "This email is already in use. Please use a different email.";
            return { error: completeInfoError.value };
          }
        }

        // Prepare the update data
        const updateData = {};

        if (userInfo.name) {
          updateData.Name = userInfo.name;
        }

        if (userInfo.email) {
          updateData.Email = userInfo.email;
        }

        // Only proceed with updating if there's data to update
        if (Object.keys(updateData).length > 0) {
          // Proceed with updating user info
          const response = await fetch(`${baseUrl}/users/update-info`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(updateData),
          });

          if (response.ok) {
            const user = await response.json();
            console.log("User info updated, user data:", user);
            hasEnteredEmail.value = !!userInfo.email;

            // Send verification email if email was updated
            if (userInfo.email) {
              await sendVerificationEmail(userInfo.email);
            }
          } else {
            const errorData = await response.json();
            if (response.status === 403) {
              console.error("403 Forbidden error:", errorData);
              return {
                error:
                  "You don't have permission to update this information. Please try logging out and logging in again.",
              };
            }
            return {
              error:
                errorData.error ||
                "An error occurred while updating user information.",
            };
          }
        }

        if (isSolanaUser.value || isGoogleUser.value) {
          registrationStep.value = "add-password";
        } else if (!userInfo.verified && userInfo.email) {
          registrationStep.value = "verify-email";
        } else {
          eventBus.emit("login", userInfo);
          message.value = "Registration successful!";
          router.push("/welcome");
        }
        return { success: true };
      } catch (error) {
        console.error("Error in handleInfoCompleted:", error);
        return { error: `Failed to update user info: ${error.message}` };
      }
    };

    const sendVerificationEmail = async (email) => {
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      try {
        const response = await fetch(`${baseUrl}/users/resend-verification`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ email }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }
      } catch (error) {
        console.error("Failed to send verification email:", error);
        message.value = "Failed to send verification email. Please try again.";
      }
    };

    const handleSkipPassword = () => {
      // eventBus.emit("login", user);
      message.value = "Registration successful!";
      router.push("/welcome");
    };

    const handlePasswordSet = async (password) => {
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      try {
        const response = await fetch(`${baseUrl}/users/set-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ password }),
        });

        if (response.ok) {
          // const user = await response.json();
          // eventBus.emit("login", user);
          message.value = "Registration successful!";
          router.push("/welcome");
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }
      } catch (error) {
        message.value = `Failed to set password: ${error.message}`;
      }
    };

    // const handleWalletTypeSelected = async (data) => {
    //   const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
    //   try {
    //     const response = await fetch(`${baseUrl}/users/update-wallet-info`, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       credentials: "include",
    //       body: JSON.stringify(data),
    //     });

    //     if (response.ok) {
    //       const user = await response.json();
    //       if (!user.verified && user.email) {
    //         registrationStep.value = "verify-email";
    //       } else {
    //         eventBus.emit("login", user);
    //         message.value = "Registration successful!";
    //         router.push("/welcome");
    //       }
    //     } else {
    //       const errorData = await response.json();
    //       throw new Error(errorData.error);
    //     }
    //   } catch (error) {
    //     message.value = `Failed to update wallet information: ${error.message}`;
    //   }
    // };

    const sessionStore = useSessionStore();

    const login = async () => {
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      try {
        console.log("Attempting login...");
        const response = await fetch(`${baseUrl}/users/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email: email.value,
            password: password.value,
          }),
        });

        console.log("Login response status:", response.status);

        if (response.ok) {
          const user = await response.json();
          console.log("Login successful, user data:", user);
          if (!user.verified) {
            // Redirect to VerifyEmail component with email as a query parameter
            router.push({ name: "VerifyEmail", query: { email: email.value } });
          } else {
            // Set the theme in the session store
            if (user.theme) {
              sessionStore.setTheme(user.theme);
            }
            eventBus.emit("login", user);
            message.value = "Login successful!";
            router.push("/dashboard");
          }
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }
      } catch (error) {
        console.error("Login error:", error);
        message.value = `Login failed: ${error.message}`;
      }
    };

    const handleVerificationCompleted = () => {
      userVerified.value = true;
      eventBus.emit("login");
      message.value = "Email verified successfully!";
      router.push("/welcome");
    };

    const resetPassword = async () => {
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      try {
        const response = await fetch(`${baseUrl}/users/reset-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ email: resetEmail.value }),
        });

        if (response.ok) {
          message.value = "Password reset instructions sent to your email.";
          showResetPasswordForm.value = false;
          showEmailFields.value = true; // Show the login form again after reset
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }
      } catch (error) {
        console.error("Password reset error:", error);
        message.value = `Password reset failed: ${error.message}`;
      }
    };

    const validatePassword = (password) => {
      const checks = [
        {
          regex: /[A-Z]/,
          message: "Password needs an uppercase letter",
        },
        { regex: /[a-z]/, message: "Password needs a lowercase letter" },
        { regex: /\d/, message: "Password needs a number" },
        {
          regex: /[@$!%*?&#^()%]/,
          message: "Password needs a special character",
        },
        { regex: /.{10,}/, message: "Password must be 10 characters" },
      ];

      for (let check of checks) {
        if (!check.regex.test(password)) {
          return check.message;
        }
      }
      return "";
    };

    const isSubmitDisabled = computed(() => !!validatePassword(password.value));

    const submitButtonText = computed(() => {
      if (password.value === "") return "Continue";
      const validationMessage = validatePassword(password.value);
      return validationMessage || "Continue";
    });

    return {
      showStartMessage,
      showEmailFields,
      showWalletMessage,
      showResendButton,
      email,
      password,
      message,
      handleEmailLogin,
      handleGoogleLogin,
      handleSolanaLogin,
      handleSubmit,
      connected,
      signSolanaMessage,
      showResetPasswordForm,
      resetEmail,
      showForgotPassword,
      resetPassword,
      isSubmitDisabled,
      submitButtonText,
      router,
      registrationStep,
      name,
      handleInfoCompleted,
      handleVerificationCompleted,
      isSolanaUser,
      handleSkipPassword,
      handlePasswordSet,
      sendVerificationEmail,
      solanaPublicKey,
      completeInfoError,
    };
  },
};
</script>

<style scoped>
/* Add any additional styling if needed */
</style>
