<script setup>
import BlogOverview from "@/components/blog/BlogOverview";
import { useHead } from "@vueuse/head";

useHead({
  title:
    "Blogs | Two Bros Inferencing | Learn More About Integrating AI Into Your Apps",
  meta: [
    {
      name: "description",
      content:
        "Learn more about integrating AI into your apps with the Two Bros Inferencing platform.",
    },
    {
      property: "og:description",
      content:
        "Learn more about integrating AI into your apps with the Two Bros Inferencing platform.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});
</script>

<template>
  <div class="text-center mt-20 md:mt-42 mb-0 p-8 overflow-x-hidden">
    <h1 class="text-4xl lg:text-6xl text-base-content">BLOG</h1>
    <p class="text-xl text-secondary-focus mb-0">Articles and insights</p>
  </div>
  <blog-overview />
</template>
