<script setup>
import { onMounted, nextTick, ref, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import MobileMenu from "./MobileMenu.vue";
import { useSessionStore } from "@/stores/session";
import LoginButton from "../login/LoginButton.vue";
import axios from "axios";

const router = useRouter();
const route = useRoute();
const isMenuOpen = ref(false);
const sessionStore = useSessionStore();

// New computed property to check if the current route is Home
const isHomePage = computed(() => route.name === "Home");

// New computed property for navbar background class
const navbarBackgroundClass = computed(() =>
  isHomePage.value ? "bg-transparent" : "bg-base-300 bg-opacity-80"
);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const smallLogoSrc = computed(() => {
  return sessionStore.theme === "cedroslight"
    ? "/images/two_bros_inferencing_logo.svg"
    : "/images/two_bros_inferencing_logo.svg";
});

const largeLogoSrc = computed(() => {
  return sessionStore.theme === "cedroslight"
    ? "/images/two_bros_inferencing_logo.svg"
    : "/images/two_bros_inferencing_logo.svg";
});

const fetchUser = async () => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BACKEND_API_URL}/auth/user`,
      { withCredentials: true }
    );
    sessionStore.setUser(response.data);
    if (response.data.theme) {
      sessionStore.setTheme(response.data.theme);
    }
  } catch (error) {
    // console.error("Error fetching user:", error);
    sessionStore.clearUser();
  }
};

onMounted(async () => {
  await fetchUser();

  nextTick(() => {
    const button = document.querySelector(".swv-button-trigger");
    if (button) {
      button.innerText = "Login";

      // Set up an observer for the modal
      const observer = new MutationObserver((mutations) => {
        for (let mutation of mutations) {
          if (mutation.type === "childList" && mutation.addedNodes.length) {
            const modalTitle = document.querySelector(".swv-modal-title");
            if (modalTitle) {
              modalTitle.innerText = "Sign In With Solana";
              observer.disconnect();
              break;
            }
          }
        }
      });

      // Start observing immediately, but target a more specific container if possible
      observer.observe(document.body, { childList: true, subtree: true });

      // Add click listener to the button
      button.addEventListener("click", () => {
        // Reinstate observation in case modal reappears
        observer.observe(document.body, { childList: true, subtree: true });
      });
    }
  });
});

// Listen for login and logout events
watch(
  () => sessionStore.isLoggedIn,
  (newValue) => {
    if (newValue) {
      fetchUser();
    }
  }
);

// Watch for route changes
watch(
  () => router.currentRoute.value.path,
  () => {
    fetchUser();
  }
);
</script>

<template>
  <div>
    <!-- NavBar / Header -->
    <div
      class="navbar fixed top-0 left-0 z-50 w-full max-w-full flex flex-row justify-between items-center"
      :class="navbarBackgroundClass"
    >
      <!-- Logo -->
      <router-link to="/" class="shrink-0">
        <img
          :src="smallLogoSrc"
          alt="Two Bros Inferencing"
          class="w-24 p-3 block lg:hidden"
        />
        <img
          :src="largeLogoSrc"
          alt="Two Bros Inferencing"
          class="w-30 p-3 hidden lg:block -mt-2"
        />
      </router-link>

      <!-- Nav Links and Wallet Button -->
      <div class="flex items-center">
        <!-- Nav Links -->
        <div class="hidden md:flex md:gap-2 nav-icon-group">
          <!-- Show these links only when user is NOT logged in -->
          <template v-if="!sessionStore.isLoggedIn">
            <router-link to="/pricing" class="nav-icon-link -mt-4">
              <font-awesome-icon class="nav-icon" :icon="['fas', 'coins']" />
              <span class="nav-text">Pricing</span>
            </router-link>
            <router-link to="/docs" class="nav-icon-link -mt-4">
              <font-awesome-icon class="nav-icon" :icon="['fas', 'file']" />
              <span class="nav-text">Docs</span>
            </router-link>
            <router-link to="/blog" class="nav-icon-link -mt-4">
              <font-awesome-icon class="nav-icon" :icon="['fas', 'book']" />
              <span class="nav-text">Blog</span>
            </router-link>
          </template>

          <!-- Show profile link when user is logged in -->
          <template v-if="sessionStore.isLoggedIn">
            <router-link to="/profile" class="nav-icon-link -mt-4">
              <font-awesome-icon class="nav-icon" :icon="['fas', 'user']" />
              <span class="nav-text">Profile</span>
            </router-link>
          </template>
        </div>

        <!-- Wallet Button -->
        <div class="lg:ml-4 mr-2 lg:mr-4 -mt-4">
          <LoginButton />
        </div>

        <!-- Hamburger Menu Icon -->
        <button @click="toggleMenu" class="md:hidden p-2">
          <font-awesome-icon
            :icon="['fas', isMenuOpen ? 'times' : 'bars']"
            class="text-base-content text-2xl"
          />
        </button>
      </div>
    </div>

    <!-- Mobile Menu -->
    <MobileMenu :isOpen="isMenuOpen" @close="isMenuOpen = false" />
  </div>
</template>

<style scoped>
.nav-icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.nav-icon {
  @apply text-base-content hover:text-secondary hover:opacity-70;
  font-size: 1.4rem;
  margin: 0px 20px;
}

.nav-text {
  @apply text-base-content;
  text-align: center;
  font-family: "Lato-Bold", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.2px;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%) translateY(-5px);
  white-space: nowrap;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden; /* initially hidden */
  transition: opacity 0.1s ease-in-out, transform 0.3s ease-in-out,
    visibility 0.1s;
}

.nav-icon-link:hover .nav-text {
  opacity: 1;
  visibility: visible; /* make visible on hover */
  transform: translateX(-50%) translateY(0);
}

.nav-icon-link:hover .nav-icon {
  transform: scale(1.1);
}

.nav-icon-link:hover .nav-text {
  display: block;
}
</style>
