<template>
  <button
    @click="logout"
    class="px-4 py-2 bg-red-500 text-base-content rounded hover:bg-red-700"
  >
    Logout
  </button>
</template>

<script>
export default {
  methods: {
    async logout() {
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      try {
        const response = await fetch(`${baseUrl}/auth/logout`, {
          method: "GET",
          credentials: "include", // Include cookies in the request
        });

        if (!response.ok) {
          throw new Error("Failed to logout");
        }

        // Redirect to the homepage or another page after logout
        window.location.href = "/";
      } catch (error) {
        console.error("Error during logout:", error);
        alert("An error occurred while logging out. Please try again.");
      }
    },
  },
};
</script>
