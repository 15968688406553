<template>
  <div class="profile-picture-container">
    <div class="profile-picture-wrapper">
      <img
        :src="computedProfileImageUrl"
        alt="Profile Picture"
        class="profile-picture"
      />
      <div class="overlay" @click="triggerFileUpload">
        <span>Edit</span>
      </div>
      <input
        type="file"
        ref="fileInput"
        @change="onFileSelected"
        class="file-input"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profileImageUrl: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    computedProfileImageUrl() {
      return (
        this.profileImageUrl ||
        "https://firebasestorage.googleapis.com/v0/b/cedros-images.appspot.com/o/profiles%2Fdefault.jpg?alt=media&token=923ca9e3-babf-4c07-9945-14b3b3ba9a9c"
      );
    },
  },
  methods: {
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadProfileImage(file);
      }
    },
    async uploadProfileImage(file) {
      try {
        // Create FormData to send the file
        const formData = new FormData();
        formData.append("profileImage", file);

        // Send the file to the backend (adjust the URL accordingly)
        const response = await fetch(
          `${process.env.VUE_APP_BACKEND_API_URL}/users/upload-image`,
          {
            method: "POST",
            body: formData,
            credentials: "include", // Include cookies if needed
          }
        );

        if (response.ok) {
          const imageUrl = await response.text();
          console.log("Image uploaded successfully:", imageUrl);
          // Emit the new image URL to the parent component
          this.$emit("image-updated", imageUrl);
        } else {
          console.error("Failed to upload image");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
  },
};
</script>

<style scoped>
.profile-picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-picture-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay span {
  font-size: 18px;
}

.profile-picture-wrapper:hover .overlay {
  opacity: 1;
}

.file-input {
  display: none;
}
</style>
