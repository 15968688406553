<script>
import { useBlogStore } from "@/stores/blogStore";
import { useSessionStore } from "@/stores/session";
import { useBookmarkStore } from "@/stores/bookmarkStore";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { onMounted, ref, watch, computed, h } from "vue";
import { useHead } from "@vueuse/head";
import {
  parseMarkdownLinks,
  scrollToSection,
} from "@/utilities/blog/blogFunctions";
import SayThanks from "./SayThanks.vue";

export default {
  name: "BlogPost",
  components: {
    SayThanks,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const blogStore = useBlogStore();
    const sessionStore = useSessionStore();
    const bookmarkStore = useBookmarkStore();
    const post = ref(null);
    const showThanks = ref(false);

    const { isLoggedIn } = storeToRefs(sessionStore);

    const fetchAndSetPost = async () => {
      if (blogStore.blogPosts.length === 0) {
        await blogStore.fetchBlogPosts();
      }
      post.value = blogStore.getPostBySlug(route.params.slug);
    };

    onMounted(fetchAndSetPost);

    useHead({
      title: computed(() =>
        post.value
          ? post.value.Title + " | Two Bros Inferencing"
          : "Blog | Two Bros Inferencing"
      ),
      meta: [
        {
          property: "og:title",
          content: computed(() =>
            post.value
              ? post.value.Title + " | Two Bros Inferencing"
              : "Blog | Two Bros Inferencing"
          ),
        },
        {
          name: "description",
          content: computed(() =>
            post.value
              ? post.value.MetaDescription
              : "Whether you're new to Solana or a seasoned pro, our blog has something for you."
          ),
        },
        {
          property: "og:description",
          content: computed(() =>
            post.value
              ? post.value.MetaDescription
              : "Whether you're new to Solana or a seasoned pro, our blog has something for you."
          ),
        },
        {
          property: "og:image",
          content:
            "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: computed(() => {
            if (!post.value) return {};

            const blogPostingStructuredData = {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": `https://twobrosinferencing.com/blog/${route.params.slug}`,
              },
              headline: post.value.Title,
              image: [
                `https://twobrosinferencing.com/${post.value.FeaturedImage}`,
              ],
              datePublished: `${post.value.CreatedAt}T00:00:00Z`,
              dateModified: `${post.value.UpdatedAt}T00:00:00Z`,
              author: {
                "@type": "Organization",
                name: post.value.Author,
                url: `https://www.twitter.com/TwoBrosInf`,
              },
              publisher: {
                "@type": "Organization",
                name: "Two Bros Inferencing",
                url: "https://twobrosinferencing.com",
                logo: {
                  "@type": "ImageObject",
                  url: "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
                },
              },
              description: post.value.Intro,
            };

            return JSON.stringify(blogPostingStructuredData);
          }),
        },
      ],
    });

    const tableOfContents = computed(() => {
      if (!post.value || !post.value.Body) return [];
      return post.value.Body.filter((content) => content.Element === "h2").map(
        (content, index) => ({
          id: `section-${index}`,
          title: content.Content,
        })
      );
    });

    const relatedPosts = computed(() => {
      console.log("Related Reading:", post.value?.RelatedReading);
      console.log("All blog posts:", blogStore.blogPosts);
      if (!post.value || !post.value.RelatedReading) return [];

      // Convert RelatedReading IDs to numbers if they're not already
      const relatedIds = post.value.RelatedReading.map((id) =>
        typeof id === "number" ? id : parseInt(id, 10)
      );

      const related = blogStore.blogPosts.filter((blogPost) =>
        relatedIds.includes(blogPost.ID_Int)
      );

      console.log("Filtered related posts:", related);
      return related;
    });

    const renderedContent = computed(() => {
      if (!post.value || !post.value.Body) return [];

      let h2Index = 0;

      return post.value.Body.map((bodyElement) => {
        const { Element, Content } = bodyElement;

        if (Element === "img") {
          return h("img", {
            src: Content,
            alt: bodyElement.AltText ? bodyElement.AltText : post.value.Title,
            class:
              "w-full h-auto object-cover object-center rounded-lg body-image",
          });
        } else if (Element === "ul" || Element === "ol") {
          if (Array.isArray(Content)) {
            return h(
              Element,
              { class: "dynamic-content" },
              Content.map((item) =>
                h("li", { innerHTML: parseMarkdownLinks(item) })
              )
            );
          } else {
            console.error("List content is not an array:", Content);
            return null;
          }
        } else {
          const props = {
            innerHTML:
              typeof Content === "string" ? parseMarkdownLinks(Content) : "",
            class: "dynamic-content",
          };
          if (Element === "h2") {
            props.id = `section-${h2Index++}`;
          }
          return h(Element, props);
        }
      }).filter(Boolean);
    });

    const isBookmarked = computed(() =>
      bookmarkStore.isBookmarked(post.value?.ID_Int)
    );

    const formattedReadingTime = computed(() => {
      const minutes = post.value?.ReadingTimeMinutes;
      if (!minutes) return "";
      if (minutes === 1) {
        return "1 minute";
      } else if (minutes < 1) {
        return "< 1 minute";
      } else {
        return `${minutes} minutes`;
      }
    });

    const formatDate = (dateString) => {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const [year, month, day] = dateString.split("-");

      const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(
        day,
        10
      )}, ${year}`;
      return formattedDate;
    };

    const bookmarkIcon = computed(() => {
      return isBookmarked.value
        ? "fa-solid fa-bookmark"
        : "fa-regular fa-bookmark";
    });

    const toggleBookmark = async () => {
      if (!isLoggedIn.value || !post.value) return;

      try {
        if (isBookmarked.value) {
          await bookmarkStore.removeBookmark(post.value.ID_Int);
        } else {
          await bookmarkStore.addBookmark(post.value.ID_Int);
        }
      } catch (error) {
        console.error("Error toggling bookmark:", error);
        // Handle error (e.g., show a notification to the user)
      }
    };

    watch(() => route.params.slug, fetchAndSetPost);

    watch(isLoggedIn, (newValue) => {
      if (newValue) {
        bookmarkStore.getUserBookmarks();
      }
    });

    const toggleThanks = () => {
      showThanks.value = !showThanks.value;
    };

    return {
      post,
      goBack: () => router.push("/blog"),
      renderedContent,
      tableOfContents,
      scrollToSection,
      relatedPosts,
      showThanks,
      toggleThanks,
      SayThanks,
      isLoggedIn,
      isBookmarked,
      formattedReadingTime,
      bookmarkIcon,
      toggleBookmark,
      formatDate,
    };
  },
};
</script>

<template>
  <div
    v-if="post"
    class="container mx-auto flex flex-col p-4 mt-20 xl:mt-42 mb-8 2xl:mb-24"
  >
    <div class="blog-content mx-auto">
      <div
        class="max-w-2xl py-4 pb-4 mb-12 px-8 bg-base-200 shadow-lg rounded-lg w-full"
      >
        <!-- X close button -->
        <div class="flex justify-end items-center py-6 -mt-2">
          <button
            @click="goBack"
            class="text-base-content hover:text-base-content text-2xl"
          >
            <font-awesome-icon
              class="w-8 h-8"
              :icon="['fas', 'circle-xmark']"
            />
          </button>
        </div>
        <!-- Post title -->
        <div class="flex justify-between items-center py-6 -mt-8">
          <h1 class="text-4xl font-semibold text-base-content">
            {{ post.Title }}
          </h1>
        </div>
        <!-- New section for reading time and bookmark -->
        <div
          class="flex items-center justify-between text-base-content mb-6 text-sm border border-neutral rounded-lg py-1 px-4 shadow-lg"
        >
          <div class="flex items-center">
            <!-- Reading time -->
            <span class="mr-4">
              <font-awesome-icon icon="fa-solid fa-clock" class="mr-1" />
              {{ formattedReadingTime }}
            </span>

            <!-- Bookmark button -->
            <button
              v-if="isLoggedIn"
              @click="toggleBookmark"
              class="text-base-content hover:text-primary-focus"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                :fill="isBookmarked ? 'currentColor' : 'none'"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                />
              </svg>
            </button>
          </div>

          <!-- Meta information -->
          <div class="flex items-center pt-3">
            <p class="text-sm">Updated: {{ formatDate(post.UpdatedAt) }}</p>
            <p class="text-sm px-2">|</p>
            <p class="text-sm">
              Author:
              <a
                :href="'https://x.com/' + post.Author"
                target="_blank"
                rel="noopener noreferrer nofollow"
                class="hover:text-primary-focus"
              >
                {{ post.Author }}
              </a>
            </p>
          </div>
        </div>
        <p class="text-base-content">{{ post.Intro }}</p>
        <!-- Mobile ToC for small screens -->
        <div class="table-of-contents my-8 2xl:hidden">
          <h2 class="text-xl font-semibold text-base-content no-top-line">
            Sections
          </h2>
          <ul>
            <template
              v-for="(item, index) in tableOfContents"
              :key="`toc-${index}`"
            >
              <li class="mt-2">
                <a
                  href="#"
                  @click="scrollToSection($event, item.id)"
                  class="text-base-content hover:text-primary-focus"
                >
                  {{ item.title }}
                </a>
                <hr
                  v-if="index < tableOfContents.length - 1"
                  class="toc-divider"
                />
              </li>
            </template>
          </ul>
        </div>
        <img
          :src="post.FeaturedImage"
          :alt="post.Title"
          class="w-full h-64 object-cover object-center rounded-lg"
        />
        <div class="py-6">
          <!-- Dynamic content rendering -->
          <template v-for="(vnode, index) in renderedContent" :key="index">
            <component :is="vnode" />
          </template>
        </div>
      </div>
    </div>
    <!-- Sidebar ToC for large screens -->
    <div class="toc-sidebar hidden 2xl:block overflow-hidden">
      <div
        class="sticky mr-16 w-75 adjust-position-for-view-height overflow-hidden"
      >
        <div
          v-if="showThanks"
          class="px-4 py-8 bg-base-100 shadow-lg rounded-lg h-135 adjust-size-for-view-height"
        >
          <SayThanks v-if="showThanks" @close="showThanks = false" />
        </div>
        <div
          v-else
          class="px-4 py-8 bg-base-100 shadow-lg rounded-lg h-135 adjust-size-for-view-height"
        >
          <h2
            class="text-xl font-semibold text-base-content px-2 py-1 no-top-line"
          >
            Sections
          </h2>
          <ul>
            <template
              v-for="(item, index) in tableOfContents"
              :key="`toc-sidebar-${index}`"
            >
              <a
                href="#"
                @click="scrollToSection($event, item.id)"
                class="text-base-content"
                ><li
                  class="toc-list text-base-content px-3 pt-2 mt-2 rounded-lg hover:bg-neutral hover:bg-opacity-70 hover:text-base-content hover:shadow-md transition duration-100 ease-in-out"
                >
                  {{ item.title }}
                </li>
              </a>
              <hr
                v-if="index < tableOfContents.length - 1"
                class="toc-divider mr-4"
              />
            </template>
          </ul>
          <div class="toc-button">
            <div
              class="flex justify-center items-center bg-primary hover:bg-primary-focus rounded-lg say-thanks-button mt-4"
              @click="toggleThanks"
            >
              <!-- <font-awesome-icon
              class="w-8 h-8 text-base-content hover:text-base-content text-2xl"
              :icon="['fas', 'circle-xmark']"
            /> -->
              <div class="flex items-center">
                <p
                  class="text-sm text-base-content hover:text-base-content m-0 py-1 font-bold"
                >
                  SAY THANKS
                </p>
              </div>
            </div>
            <div
              class="flex justify-center items-center bg-neutral-focus hover:bg-base-100 rounded-lg mt-2"
              @click="goBack"
            >
              <!-- <font-awesome-icon
              class="w-8 h-8 text-base-content hover:text-base-content text-2xl"
              :icon="['fas', 'circle-xmark']"
            /> -->
              <div class="flex items-center">
                <p
                  class="text-sm text-base-content hover:text-base-content m-0 py-1 font-bold"
                >
                  BACK TO BLOGS
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- New Section for Related Reading -->
    <!-- <section class="related-reading mx-auto max-w-2xl px-0 text-base-content">
      <h2 class="mx-auto justify-center">Related Reading</h2>
      <div class="cards-container max-w-lg">
        <div
          class="card bg-base-200 my-16 mb-20 pb-8 px-8 rounded-lg"
          v-for="relatedPost in relatedPosts"
          :key="relatedPost.ID"
        >
          <img
            :src="relatedPost.FeaturedImage"
            :alt="relatedPost.Title"
            class="card-img rounded-lg -mt-6 h-48 object-cover object-center"
          />
          <h3 class="text-left">{{ relatedPost.Title }}</h3>
          <p class="text-base-content">{{ relatedPost.Intro }}</p>
          <router-link
            class="w-32 rounded-full bg-primary text-base-content mt-2 py-1 px-4 shadow-xl hover:bg-primary-focus hover:text-base-content text-center"
            :to="`/blog/${relatedPost.Slug}`"
            >Read More
          </router-link>
        </div>
      </div>
    </section> -->
    <!-- New Section for Related Reading -->
    <section
      v-if="relatedPosts.length > 0"
      class="related-reading mx-auto max-w-2xl px-0 text-base-content"
    >
      <h2 class="mx-auto justify-center">Related Reading</h2>
      <div class="cards-container max-w-lg">
        <div
          class="card bg-base-200 my-16 mb-20 pb-8 px-8 rounded-lg"
          v-for="relatedPost in relatedPosts"
          :key="relatedPost.ID_Int"
        >
          <img
            :src="relatedPost.FeaturedImage"
            :alt="relatedPost.Title"
            class="card-img rounded-lg -mt-6 h-48 object-cover object-center"
          />
          <h3 class="text-left">{{ relatedPost.Title }}</h3>
          <p class="text-base-content">{{ relatedPost.Intro }}</p>
          <router-link
            class="w-32 rounded-full bg-primary text-base-content mt-2 py-1 px-4 shadow-xl hover:bg-primary-focus hover:text-base-content text-center"
            :to="`/blog/${relatedPost.Slug}`"
          >
            Read More
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 768px) 1fr;
  gap: 0px;
}
.blog-content,
.related-reading {
  grid-column: 2 / 3;
}

.toc-sidebar {
  display: none;
}

@media (min-width: 1400px) {
  .container {
    grid-template-columns: 1fr minmax(auto, 768px) 1fr;
  }

  .blog-content,
  .related-reading {
    grid-column: 2 / 3;
  }

  .toc-sidebar {
    display: block;
    grid-column: 3 / 4;
  }

  .toc-list {
    padding-bottom: 0px;
    cursor: pointer;
  }

  .sticky {
    position: fixed;
    top: 240px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-base-content;
}

p {
  @apply text-base-content;
}

h2 {
  @apply text-2xl mt-4 pt-8;
  font-family: BNBinder;
  border-top: 1px solid #ccc;
}

h2:not(:first-of-type) {
  @apply mt-12;
}

.no-top-line {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

h3 {
  @apply mt-8 text-lg;
  font-family: Lato-Bold;
}

h4 {
  @apply mt-6 text-base text-base-content;
  font-family: Lato-Bold;
}

h5 {
  @apply mt-4 text-base text-base-content;
  font-family: Lato-Bold;
}

.dynamic-content img {
  width: 100%;
  height: 64px;
  object-fit: cover;
  object-position: center;
  border-radius: 0.5rem;
}

.dynamic-content h2 {
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.dynamic-content h3 {
  margin-top: 0.75rem;
  font-size: 1.125rem;
  font-weight: 600;
}

.dynamic-content p {
  margin-top: 0.5rem;
  font-size: 1rem;
}

/* Lists */
ul,
ol {
  padding-left: 20px;
  margin: 10px 0;
  list-style-type: inherit;
}

li {
  @apply text-base-content;
  margin-bottom: 10px;
}

.table-of-contents {
  list-style-type: none;
}

.table-of-contents ul,
ol {
  padding-left: 0px;
}

.toc-sidebar {
  list-style-type: none;
}

.toc-sidebar ul,
ol {
  padding-left: 0px;
}

.toc-divider {
  margin-top: 8px;
  margin-bottom: 0;
  border: none;
  height: 1px;
  background-color: #ccc;
}

ul a li:last-child {
  padding-bottom: 8px;
}

.related-reading h2 {
  text-align: center;
}

.related-reading .card h3 {
  text-align: left;
  width: 100%;
}

.related-reading .card .router-link {
  text-align: left;
  display: inline-block;
  margin-top: 1rem;
}

.related-reading .card-img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.body-image {
  /* Your styles here */
  margin: 10px 0px 20px;
}

.toc-button {
  cursor: pointer;
  position: relative;
  bottom: 0;
}

.toc-sidebar .sticky {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.toc-sidebar .sticky > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toc-sidebar .sticky ul {
  flex-grow: 1;
  overflow-y: auto;
}

.toc-sidebar ::-webkit-scrollbar {
  width: 12px;
  background-color: #f9f9fd;
}

.toc-sidebar ::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Style the handle of scrollbar */
.toc-sidebar ::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 6px;
  border: 3px solid #f0f0f0;
}

/* Handle on hover */
.toc-sidebar ::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}

.toc-sidebar {
  scrollbar-width: thin;
  scrollbar-color: #78716c #18181b;
}

img + h3 {
  @apply mt-2;
}

@media (min-width: 1400px) and (max-height: 1000px) {
  .adjust-position-for-view-height {
    top: 168px;
    margin-left: -30px;
  }
  .adjust-size-for-view-height {
    height: 420px;
  }
}
</style>
