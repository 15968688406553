<template>
  <div class="p-4">
    <h1 class="text-2xl font-bold mb-4">Testing Page</h1>
    <div class="w-120 mx-auto mt-20">
      <!-- <GoogleLoginButton /> -->
      <LogoutButton />
      <UserProfile />
      <!-- <AuthForm /> -->
      <p>------------------------------------</p>
      <LoginModal />
      <FaqSection />
      <ActionGrid />
    </div>
    <!-- Add this line -->
  </div>
</template>

<script>
// import GoogleLoginButton from "@/components/login/GoogleLoginButton.vue";
import LogoutButton from "@/components/login/LogoutButton.vue";
import UserProfile from "@/components/login/UserProfile.vue";
// import AuthForm from "@/components/login/AuthForm.vue"; // Import the AuthForm component
import LoginModal from "@/components/login/LoginModal.vue";
import FaqSection from "@/components/faq/FaqSection.vue";
import ActionGrid from "@/components/chat/ActionGrid.vue";

export default {
  components: {
    // GoogleLoginButton,
    LogoutButton,
    UserProfile,
    // AuthForm, // Register the AuthForm component
    LoginModal,
    FaqSection,
    ActionGrid,
  },
};
</script>

<style scoped>
/* Add any additional styling if needed */
</style>
