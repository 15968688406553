// blogStore.js
import { defineStore } from "pinia";
import axios from "axios";

export const useBlogStore = defineStore("blog", {
  state: () => ({
    blogPosts: [],
    categories: ["News", "Opinions", "Spotlight", "Tutorials"],
    activeCategories: new Set(["News", "Opinions", "Spotlight", "Tutorials"]), // Ensure this is initialized as an array
    selectedKnowledgeLevel: "All", // Default initialization
    isLoading: false,
    error: null,
  }),
  getters: {
    getPostBySlug: (state) => (slug) => {
      return state.blogPosts.find((post) => post.Slug === slug);
    },
  },
  actions: {
    async fetchBlogPosts() {
      const maxRetries = 5; // Maximum number of retry attempts
      const retryDelay = 300; // Delay between retries in milliseconds

      // Helper function to delay execution
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      // Reset state before fetching
      this.isLoading = true;
      this.error = null;

      for (let attempt = 1; attempt <= maxRetries; attempt++) {
        try {
          // console.log(`Fetching blog posts... (Attempt ${attempt})`);

          const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_API_URL}/blogs`,
            {
              headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
            }
          );

          // Update state with fetched data
          this.blogPosts = response.data;
          this.isLoading = false;
          return; // Exit the function if successful
        } catch (error) {
          console.error(
            `Error fetching blog posts (Attempt ${attempt}):`,
            error
          );

          if (
            error.response &&
            error.response.status === 503 &&
            attempt < maxRetries
          ) {
            // If it's a 503 error and not the last attempt, wait before retrying
            // console.log(`Retrying in ${retryDelay / 1000} seconds...`);
            await delay(retryDelay);
          } else {
            // If it's not a 503 error or it's the last attempt, set the error state
            this.error =
              error.message || "An error occurred while fetching blog posts";
            this.isLoading = false;
            break; // Exit the retry loop
          }
        }
      }

      // If we've exhausted all retries, log a final error message
      if (this.error) {
        console.error("Failed to fetch blog posts after multiple attempts");
      }
    },
    toggleCategory(category) {
      if (this.activeCategories.has(category)) {
        this.activeCategories.delete(category);
      } else {
        this.activeCategories.add(category);
      }
      // Since Sets are not reactive by default in Vue 3, trigger reactivity:
      this.activeCategories = new Set(this.activeCategories);
    },

    setActiveCategories(categories) {
      if (categories === "All") {
        //console.log("setting to all categories");
        this.activeCategories = new Set(this.categories);
        //console.log("activeCategories:", this.activeCategories);
      } else {
        this.activeCategories = new Set([categories]);
      }
    },
    setKnowledgeLevel(level) {
      // Mapping textual representations to numeric values
      const levelMap = {
        All: "All",
        Beginner: 1,
        Intermediate: 2,
        Advanced: 3,
      };

      // Update the state with the numeric value
      this.selectedKnowledgeLevel = levelMap[level];
    },
  },
});
