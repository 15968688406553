<script setup>
import { ref } from "vue";

const dashboardTools = ref([
  {
    name: "API Key",
    description: "View and manage your API key",
    icon: "fa-solid fa-key",
    tool: "apikey",
  },
  {
    name: "Alerts",
    description: "Manage your usage alerts",
    icon: "fa-solid fa-bell",
    tool: "alerts",
  },
  {
    name: "Referrals",
    description: "View and manage your referrals",
    icon: "fa-solid fa-user-plus",
    tool: "referrals",
  },

  // Add more tools here in the future
]);
</script>

<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
    <div
      v-for="tool in dashboardTools"
      :key="tool.name"
      class="card bg-base-200 text-base-content border border-base-100 shadow-lg hover:bg-base-100 hover:text-base-content hover:border-primary-focus transition duration-300"
    >
      <router-link :to="{ name: 'Dashboard', params: { tool: tool.tool } }">
        <div class="card-body">
          <h2 class="card-title">
            <font-awesome-icon :icon="tool.icon" class="mr-2" />
            {{ tool.name }}
          </h2>
          <p>{{ tool.description }}</p>
        </div>
      </router-link>
    </div>
  </div>
</template>
