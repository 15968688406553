<template>
  <div class="relative">
    <textarea
      id="prompt-input"
      ref="textareaRef"
      v-model="prompt"
      rows="1"
      placeholder="Try our LLM by sending a message..."
      class="w-full bg-base-100 text-base-content placeholder-base-content rounded-lg py-3 pl-4 pr-10 focus:outline-none focus:ring-2 focus:ring-primary resize-none overflow-hidden"
      @input="adjustTextareaHeight"
      @keydown="handleKeyDown"
    ></textarea>
    <button
      class="absolute right-3 bottom-3 text-base-content hover:text-base-content"
      @click="submitPrompt"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, defineExpose, defineEmits } from "vue";

const emit = defineEmits(["prompt-sent"]);

const prompt = ref("");
const textareaRef = ref(null);

const submitPrompt = () => {
  if (prompt.value.trim()) {
    console.log("Submitted prompt:", prompt.value);
    emit("prompt-sent", prompt.value);
    prompt.value = ""; // Clear the input after submission
    resetTextareaHeight(); // Reset the textarea height
  }
};

const setAndSubmitPrompt = (newPrompt) => {
  prompt.value = newPrompt;
  submitPrompt();
};

const adjustTextareaHeight = () => {
  const textarea = textareaRef.value;
  if (textarea) {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }
};

const resetTextareaHeight = () => {
  const textarea = textareaRef.value;
  if (textarea) {
    textarea.style.height = "auto"; // This resets to the original height
  }
};

const handleKeyDown = (event) => {
  if (event.key === "Enter" && !event.shiftKey) {
    event.preventDefault();
    submitPrompt();
  }
};

onMounted(() => {
  adjustTextareaHeight();
});

defineExpose({
  setAndSubmitPrompt,
});
</script>
