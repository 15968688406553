<template>
  <div class="directory-card bg-base-200 shadow-md relative">
    <div class="flex flex-row w-full items-center mb-6">
      <div class="flex justify-start flex-grow">
        <img class="item-image" :src="item.image" :alt="item.title" />
        <h3 class="item-title">{{ item.title }}</h3>
      </div>
      <div class="flex justify-end">
        <div
          v-if="formattedMarketCap !== '0'"
          class="market-cap bg-neutral-focus rounded-md w-12 h-12 p-2 justify-center items-center"
        >
          <p class="m-0 p-0 mt-1 text-base-content data-square-text">
            {{ formattedMarketCap }}
          </p>
          <p class="m-0 p-0 text-base-content data-square-title">MCAP</p>
        </div>
      </div>
    </div>
    <p class="text-base-content mb-4">{{ item.slogan }}</p>
    <div class="flex justify-start items-end mt-auto flex-wrap gap-2">
      <div
        v-for="(category, index) in categories"
        :key="index"
        class="item-category bg-primary text-base-content w-24 rounded-full text-center align-middle px-2 py-1"
      >
        {{ category }}
      </div>
    </div>
    <!-- Favorite and Alert icons -->
    <div class="absolute bottom-6 right-4 flex space-x-2">
      <svg
        v-if="isLoggedIn && isFavorited"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="w-6 h-6 fill-current text-primary-focus"
      >
        <path
          d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
        />
      </svg>
      <svg
        v-if="isLoggedIn && hasAlert"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="w-6 h-6 fill-current text-primary-focus"
      >
        <path
          d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"
        />
      </svg>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  hasAlert: {
    type: Boolean,
    required: true,
  },
  isFavorited: {
    type: Boolean,
    required: true,
  },
  isLoggedIn: {
    type: Boolean,
    required: true,
  },
});

// Computed property for formatted market cap
const formattedMarketCap = computed(() => {
  return formatMarketCap(props.item);
});

// Computed property for categories
const categories = computed(() => {
  // Handle cases where categories might be missing or not an array
  if (!props.item.categories) {
    console.warn(`Item ${props.item.title} is missing categories.`);
    return [];
  }
  if (!Array.isArray(props.item.categories)) {
    console.warn(
      `Categories for ${props.item.title} is not an array. Converting to array.`
    );
    return [props.item.categories];
  }
  return props.item.categories;
});

// Method to format market cap
function formatMarketCap(item) {
  const value = item.calculatedMarketCap;
  if (typeof value !== "number" || isNaN(value)) {
    return "N/A";
  }
  const formatNumber = (num, divisor, suffix) => {
    const wholePart = Math.floor(num / divisor).toString();
    if (wholePart.length >= 3) {
      return wholePart + suffix;
    }
    return (num / divisor).toFixed(3 - wholePart.length) + suffix;
  };

  if (value < 1e3) return value.toString();
  if (value >= 1e3 && value < 1e6) return formatNumber(value, 1e3, "K");
  if (value >= 1e6 && value < 1e9) return formatNumber(value, 1e6, "M");
  if (value >= 1e9) return formatNumber(value, 1e9, "B");
  return value.toString();
}
</script>

<style scoped>
.directory-card {
  @apply text-base-content border-2 border-neutral-focus hover:border-base-content active:outline-none active:ring-2 active:ring-secondary;
  padding: 20px;
  border-radius: 8px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.item-image {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.item-title {
  @apply text-base-content text-lg;
  font-size: 1.2rem;
  font-family: Lato-Bold;
  margin-top: 2px;
}

.data-square-text {
  font-size: 0.75rem;
  line-height: 0.7rem;
  font-family: Lato-Bold;
}

.data-square-title {
  font-size: 0.6rem;
  font-family: Lato-Regular;
}

.item-category {
  font-size: 0.8rem;
  font-family: Lato-Bold;
}
</style>
