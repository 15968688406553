<template>
  <div class="min-h-screen text-base-content flex items-center justify-center">
    <div class="text-center">
      <div class="mb-8">
        <svg
          class="mx-auto h-32 w-32 text-gray-400 magnifying-glass animate-pulse"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
      <h1 class="text-4xl font-bold mb-4">404 - Page Not Found</h1>
      <p class="text-xl mb-8">The page you're looking for doesn't exist.</p>
      <button
        @click="goHome"
        class="px-4 py-2 mb-2 text-base-content rounded bg-primary hover:bg-primary-focus transition duration-300 hover:scale-105"
      >
        Go back to home
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>
