<template>
  <div
    class="fixed inset-0 bg-base-300 bg-opacity-80 overflow-y-auto h-full w-full text-base-content"
    id="edit-modal"
    @click.self="closeModal"
  >
    <div
      class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-xl bg-base-200"
    >
      <div class="mt-6 text-left">
        <h2
          class="text-2xl leading-6 font-medium text-base-content text-center mb-6"
        >
          Edit Alert
        </h2>
        <div class="mt-2 px-4 py-3">
          <div class="mb-4">
            <label
              class="block text-base-content text-sm font-bold mb-2"
              for="alertType"
            >
              Alert Type
            </label>
            <select
              v-model="editedAlert.type"
              id="alertType"
              class="w-full p-2 border border-neutral bg-base-100 rounded text-gray-300"
            >
              <option value="priceTarget">Price Target</option>
              <option value="priceMovement">Price Movement</option>
            </select>
          </div>
          <div class="mb-4">
            <label
              class="block text-base-content text-sm font-bold mb-2"
              for="priceInput"
            >
              {{
                editedAlert.type === "priceTarget"
                  ? "Price Target"
                  : "Price Movement (%)"
              }}
            </label>
            <input
              v-model="editedAlert.price"
              type="number"
              id="priceInput"
              class="w-full p-2 border border-neutral bg-base-100 rounded"
              step="0.01"
            />
          </div>
          <div class="mb-4">
            <label
              class="block text-base-content text-sm font-bold mb-2"
              for="frequency"
            >
              Frequency
            </label>
            <select
              v-model="editedAlert.frequency"
              id="frequency"
              class="w-full p-2 border border-neutral bg-base-100 rounded"
            >
              <option value="oneTime">One time</option>
              <option value="everyTime">Every time</option>
            </select>
          </div>
          <div class="mb-4">
            <label
              class="block text-base-content text-sm font-bold mb-2"
              for="email"
            >
              Email (Optional)
            </label>
            <input
              v-model="editedAlert.email"
              type="email"
              id="email"
              class="w-full p-2 border border-neutral bg-base-100 rounded"
            />
          </div>
          <div class="mb-4">
            <label
              class="block text-base-content text-sm font-bold mb-2"
              for="discordWebhook"
            >
              Discord Webhook URL (Optional)
            </label>
            <input
              v-model="editedAlert.discordWebhook"
              type="url"
              id="discordWebhook"
              class="w-full p-2 border border-neutral bg-base-100 rounded"
            />
          </div>
        </div>
        <div class="items-center px-4 py-3">
          <button
            @click="submitEdit"
            class="w-full px-4 py-2 text-base-content rounded transition duration-300"
            :class="buttonClass"
          >
            {{ buttonText }}
          </button>
          <p v-if="showErrorMessage" class="text-red-500 text-sm mt-2">
            Please enter either an email or a Discord webhook URL.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

const props = defineProps({
  alert: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["closeModal", "editAlert"]);

const editedAlert = ref({ ...props.alert });
const showErrorMessage = ref(false);

onMounted(() => {
  console.log("EditAlertsModal mounted. Alert:", props.alert);
});

const isFormValid = computed(() => {
  return (
    editedAlert.value.price &&
    (editedAlert.value.email || editedAlert.value.discordWebhook)
  );
});

const buttonClass = computed(() => {
  return isFormValid.value
    ? "bg-primary hover:bg-primary-focus"
    : "bg-neutral-focus hover:bg-base-100 cursor-not-allowed";
});

const buttonText = computed(() => {
  return isFormValid.value ? "Save Changes" : "Please add a contact method";
});

const submitEdit = () => {
  if (isFormValid.value) {
    console.log("Submitting edited alert:", editedAlert.value);
    emit("editAlert", editedAlert.value);
    closeModal();
  } else {
    showErrorMessage.value = true;
  }
};

const closeModal = () => {
  emit("closeModal");
};
</script>
