<template>
  <div class="p-8 bg-base-100 rounded-xl shadow-lg max-w-md mx-auto">
    <h2 class="text-2xl font-bold text-center text-base-content mb-6">
      Email Verification
    </h2>
    <p v-if="loading" class="text-center text-base-content">
      Verifying your email...
    </p>
    <div v-else>
      <p v-if="verified" class="text-center text-green-500 mb-4">
        Your email has been successfully verified!
      </p>
      <p v-else class="text-center text-red-500 mb-4">{{ errorMessage }}</p>
      <button
        @click="goToHomepage"
        class="w-full px-4 py-2 bg-neutral-focus text-base-content rounded hover:bg-base-300"
      >
        Continue to Homepage
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  props: {
    token: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const loading = ref(true);
    const verified = ref(false);
    const errorMessage = ref("");

    // Use computed property for token
    const token = computed(() => props.token || route.query.token);

    console.log("Props:", props);
    console.log("Route query:", route.query);
    console.log("Computed token:", token.value);

    const verifyEmail = async () => {
      try {
        console.log("Verifying email with token:", token.value);
        const response = await fetch(
          `${process.env.VUE_APP_BACKEND_API_URL}/verify-email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: token.value }),
          }
        );

        console.log("Response status:", response.status);
        const data = await response.json();
        console.log("Response data:", data);

        if (response.ok) {
          verified.value = true;
        } else {
          errorMessage.value = data.error || "Failed to verify email";
        }
      } catch (error) {
        console.error("Error verifying email:", error);
        errorMessage.value = `An error occurred while verifying your email: ${error.message}`;
      } finally {
        loading.value = false;
      }
    };

    const goToHomepage = () => {
      router.push({ name: "Home" });
    };

    onMounted(() => {
      verifyEmail();
    });

    return {
      loading,
      verified,
      errorMessage,
      goToHomepage,
    };
  },
};
</script>
