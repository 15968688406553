<template>
  <div class="container mx-auto p-8 bg-base-200 w-135 rounded-lg shadow-md">
    <div class="bg-base-100 p-4 rounded-lg shadow-md mb-4">
      <div class="flex items-center justify-between">
        <span class="text-base-content">{{
          showApiKey ? apiKey : maskedApiKey
        }}</span>
        <div class="flex space-x-2">
          <button
            @click="toggleApiKeyVisibility"
            class="btn btn-ghost btn-sm px-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </button>
          <button @click="copyApiKey" class="btn btn-ghost btn-sm px-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="flex space-x-4 w-full justify-between">
      <button
        @click="showResetConfirmation"
        class="w-46 px-4 py-2 text-base-content rounded transition duration-300 bg-neutral hover:bg-neutral-focus"
      >
        Reset Key
      </button>
      <button
        @click="copyApiKey"
        class="w-46 px-4 py-2 text-base-content rounded transition duration-300 bg-primary hover:bg-primary-focus"
      >
        Copy Key
      </button>
    </div>

    <!-- Confirmation Modal -->
    <dialog ref="resetConfirmationModal" class="modal">
      <div class="modal-box bg-base-100">
        <h2 class="font-bold text-2xl">Confirm API Key Reset</h2>
        <p class="py-4">
          Are you sure you want to reset your API key? This action cannot be
          undone.
        </p>
        <div class="modal-action flex justify-between">
          <button
            @click="closeResetConfirmation"
            class="w-46 px-4 py-2 text-base-content rounded transition duration-300 bg-neutral hover:bg-neutral-focus"
          >
            Cancel
          </button>
          <button
            @click="confirmResetApiKey"
            class="w-46 px-4 py-2 text-base-content rounded transition duration-300 bg-primary hover:bg-primary-focus"
          >
            Reset Key
          </button>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useSessionStore } from "@/stores/session";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import axios from "axios";

const sessionStore = useSessionStore();
const apiKey = ref("");
const showApiKey = ref(false);
const resetConfirmationModal = ref(null);

apiKey.value = sessionStore.user.apiKey;

const maskedApiKey = computed(() => {
  if (!apiKey.value) return "••••••••••••••••";
  return `${apiKey.value.slice(0, 4)}••••••••${apiKey.value.slice(-4)}`;
});

const toggleApiKeyVisibility = () => {
  showApiKey.value = !showApiKey.value;
};

const toastTheme = computed(() =>
  sessionStore.theme === "customdark" ? "dark" : "light"
);

watch(toastTheme, (newTheme) => {
  console.log("Toast Theme changed to:", newTheme);
});

const copyApiKey = () => {
  navigator.clipboard
    .writeText(apiKey.value)
    .then(() => {
      toast.success("API key copied to clipboard", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        theme: toastTheme.value,
      });
    })
    .catch((err) => {
      console.error("Failed to copy API key:", err);
      toast.error("Failed to copy API key", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        theme: toastTheme.value,
      });
    });
};

const showResetConfirmation = () => {
  resetConfirmationModal.value.showModal();
};

const closeResetConfirmation = () => {
  resetConfirmationModal.value.close();
};

const confirmResetApiKey = async () => {
  closeResetConfirmation();
  await resetApiKey();
};

const resetApiKey = async () => {
  try {
    console.log("Resetting API key");
    const resetUrl = `${process.env.VUE_APP_BACKEND_API_URL}/users/apikey/reset`;
    console.log("Reset URL:", resetUrl);
    const response = await axios.post(resetUrl, {}, { withCredentials: true });
    console.log("Reset API key response:", response.data);
    apiKey.value = response.data.newApiKey;
    sessionStore.user.apiKey = response.data.newApiKey; // Update the store
    toast.success("API key reset successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });
  } catch (error) {
    console.error(
      "Failed to reset API key:",
      error.response ? error.response.data : error.message
    );
    toast.error(
      "Failed to reset API key: " +
        (error.response ? error.response.data : error.message),
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      }
    );
  }
};
</script>

<style scoped>
.modal::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
