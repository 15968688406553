<template>
  <div class="min-h-screen flex flex-col">
    <div class="flex-grow flex flex-col items-center justify-between py-8">
      <div class="max-w-4xl w-80 sm:w-full px-4">
        <!-- Logo -->
        <div class="flex justify-center mb-12">
          <img
            v-if="!hasPromptBeenSent"
            src="/images/two_bros_inferencing_logo_small.svg"
            alt="Logo"
            class="w-12 h-12"
          />
        </div>

        <!-- Conversation stream -->
        <div
          v-if="conversation.length > 0"
          class="mb-8 space-y-5 max-h-96 overflow-y-auto"
        >
          <div
            v-for="(message, index) in conversation"
            :key="index"
            class="flex"
            :class="message.type === 'user' ? 'justify-end' : 'justify-start'"
          >
            <div
              class="max-w-[80%] px-4 py-2 rounded-lg"
              :class="
                message.type === 'user'
                  ? 'bg-neutral-focus text-base-content rounded-br-none'
                  : 'bg-base-300 text-base-content rounded-bl-none'
              "
            >
              <div class="flex items-start">
                <img
                  v-if="message.type === 'ai'"
                  src="/images/two_bros_inferencing_logo_small.svg"
                  alt="AI"
                  class="w-6 h-6 ml-0 mr-4 mt-1"
                />
                <p>{{ message.content }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Grid of action cards -->
        <div
          v-if="!hasPromptBeenSent"
          class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8"
        >
          <ActionCard
            v-for="action in actions"
            :key="action.title"
            :action="action"
            @click="sendInitialPrompt(action.title)"
          />
        </div>
      </div>
    </div>

    <!-- Prompt input at the bottom -->
    <div class="w-80 sm:w-full max-w-4xl mx-auto px-4 mb-8">
      <PromptInput ref="promptInputRef" @prompt-sent="onPromptSent" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import ActionCard from "./ActionCard.vue";
import PromptInput from "./PromptInput.vue";

// Define the actions data
const actions = ref([
  {
    icon: "🔍",
    title: "Show me the most popular articles this week",
    color: "text-purple-400",
  },
  {
    icon: "📚",
    title: "What are the top 5 blog categories?",
    color: "text-blue-400",
  },
  {
    icon: "🗺️",
    title: "Find a directory listing for [business/service]",
    color: "text-yellow-400",
  },
  {
    icon: "📰",
    title: "What's trending in the [topic] category?",
    color: "text-green-400",
  },
]);

const hasPromptBeenSent = ref(false);
const promptInputRef = ref(null);
const conversation = ref([]);

const onPromptSent = (prompt) => {
  hasPromptBeenSent.value = true;
  addToConversation("user", prompt);
  simulateAIResponse();
};

const sendInitialPrompt = (prompt) => {
  if (promptInputRef.value) {
    promptInputRef.value.setAndSubmitPrompt(prompt);
  }
};

const addToConversation = (type, content) => {
  conversation.value.push({ type, content });
  scrollToBottom();
};

const simulateAIResponse = () => {
  setTimeout(() => {
    const dummyResponses = [
      "Here are the most popular articles this week...",
      "The top 5 blog categories are...",
      "I found the following directory listings for [business/service]...",
      "The trending topics in [topic] category are...",
    ];
    const randomResponse =
      dummyResponses[Math.floor(Math.random() * dummyResponses.length)];
    addToConversation("ai", randomResponse);
  }, 1000); // Simulate a 1-second delay for the AI response
};

const scrollToBottom = () => {
  setTimeout(() => {
    const conversationElement = document.querySelector(".conversation-stream");
    if (conversationElement) {
      conversationElement.scrollTop = conversationElement.scrollHeight;
    }
  }, 0);
};

// Function to handle the keyboard shortcut
const handleKeyDown = (event) => {
  if ((event.metaKey || event.ctrlKey) && event.key === "k") {
    event.preventDefault();
    document.getElementById("prompt-input").focus();
  }
};

// Add and remove event listener for keyboard shortcut
onMounted(() => {
  window.addEventListener("keydown", handleKeyDown);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyDown);
});
</script>
