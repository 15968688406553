<template>
  <div
    class="blog-post-card mt-24 px-8 pb-6 bg-base-200 text-base-content shadow-lg rounded-lg w-full mx-auto"
  >
    <div class="flex justify-center md:justify-end -mt-8 w-full">
      <router-link
        :to="'/blog/' + post.Slug"
        class="w-full h-40 rounded-lg border-2 border-neutral p-0 m-0 -mt-4"
      >
        <img
          class="w-full h-40 object-cover p-0 m-0 rounded-lg"
          :src="post.FeaturedImage"
          :alt="post.Title"
        />
      </router-link>
    </div>
    <div class="mt-8 flex flex-col h-full">
      <div>
        <h2 class="text-base-content text-3xl font-semibold">
          {{ post.Title }}
        </h2>
        <p class="mt-2 text-base-content">{{ truncatedIntro }}...</p>
      </div>
      <div class="mt-auto pt-6 flex justify-between items-center">
        <div class="flex items-center">
          <span class="text-sm text-base-content mr-3">
            <!-- Clock icon -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 inline-block mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {{ formattedReadingTime }}
          </span>
          <div class="flex items-center">
            <button
              v-if="isLoggedIn"
              @click="toggleBookmark"
              class="text-base-content hover:text-primary-focus"
            >
              <!-- Bookmark icon (filled when bookmarked, outline when not) -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                :fill="isBookmarked ? 'currentColor' : 'none'"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                />
              </svg>
            </button>
            <transition name="fade">
              <span v-if="showSavedMessage" class="ml-2 text-sm text-success"
                >Saved</span
              >
            </transition>
          </div>
        </div>
        <router-link
          :to="'/blog/' + post.Slug"
          class="rounded-full bg-primary-focus text-base-content py-1 px-4 shadow-xl"
        >
          Read More
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useBookmarkStore } from "@/stores/bookmarkStore";

const bookmarkStore = useBookmarkStore();

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  isBookmarked: {
    type: Boolean,
    required: true,
  },
  isLoggedIn: {
    type: Boolean,
    required: true,
  },
});

const showSavedMessage = ref(false);

const truncatedIntro = computed(() => {
  return props.post.Intro ? props.post.Intro.substring(0, 100) : "";
});

const formattedReadingTime = computed(() => {
  const minutes = props.post.ReadingTimeMinutes;
  if (minutes === 1) {
    return "1 minute";
  } else if (minutes < 1) {
    return "< 1 minute";
  } else {
    return `${minutes} minutes`;
  }
});

const toggleBookmark = async () => {
  if (!props.isLoggedIn) return;

  try {
    if (props.isBookmarked) {
      await bookmarkStore.removeBookmark(props.post.ID_Int);
    } else {
      await bookmarkStore.addBookmark(props.post.ID_Int);
      showSavedMessage.value = true;
      setTimeout(() => {
        showSavedMessage.value = false;
      }, 2000); // Hide the message after 2 seconds
    }
  } catch (error) {
    console.error("Error toggling bookmark:", error);
    // Handle error (e.g., show a notification to the user)
  }
};
</script>

<style scoped>
.blog-post-card {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
