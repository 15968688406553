<script setup>
import { useHead } from "@vueuse/head";
import UserSettings from "@/components/login/UserSettings.vue";

useHead({
  title: "Settings | Two Bros Inferencing",
  meta: [
    {
      name: "description",
      content: "View or edit your account's settings.",
    },
    {
      property: "og:description",
      content: "View or edit your account's settings.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});
</script>

<template>
  <div
    class="md:hero mx-auto p-4 w-full overflow-x-hidden"
    style="min-height: 100vh"
  >
    <div class="w-full flex flex-col mt-0">
      <UserSettings class="w-120" />
    </div>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
