// routes.js | @/routes.js
// import { auth, db } from "@/composables/FirebaseInit";
// import { doc, getDoc } from "firebase/firestore";
import BlogPost from "@/components/blog/BlogPost.vue";

const routes = [
  {
    name: "Home",
    path: "/",
    component: require("@/pages/HomePage").default,
  },
  {
    path: "/login",
    name: "Login",
    component: require("@/pages/LoginPage").default,
  },
  {
    path: "/register",
    name: "Register",
    component: require("@/pages/RegisterPage").default,
  },
  {
    path: "/profile",
    name: "Profile",
    component: require("@/pages/UserProfilePage").default,
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: require("@/pages/VerifyEmailPage").default,
    props: (route) => ({ email: route.query.email }),
  },
  {
    path: "/verify-email-confirmation",
    name: "VerifyEmailConfirmation",
    component: require("@/pages/VerifyEmailConfirmationPage").default,
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: require("@/pages/ResetPasswordPage").default,
  },
  {
    path: "/settings",
    name: "Settings",
    component: require("@/pages/SettingsPage").default,
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: require("@/pages/WelcomePage").default,
  },
  {
    path: "/dashboard/:tool?",
    name: "Dashboard",
    component: require("@/pages/DashboardPage").default,
    props: true,
  },
  {
    name: "About",
    path: "/about",
    component: require("@/pages/AboutPage").default,
  },
  {
    name: "FAQ",
    path: "/faq",
    component: require("@/pages/FaqPage").default,
  },
  {
    name: "Pricing",
    path: "/pricing",
    component: require("@/pages/PricingPage").default,
  },
  {
    name: "Learn",
    path: "/learn",
    component: require("@/pages/LearnPage").default,
  },
  {
    // Dynamic route for specific learning paths and optional slide number
    name: "LearnPath",
    path: "/learn/:pathId",
    component: require("@/pages/LearnPage").default,
    props: true,
  },
  {
    name: "LearnPathComplete",
    path: "/learn/:pathId/complete",
    component: () => import("@/components/learn/LearnPathComplete.vue"),
    props: true,
  },
  {
    name: "Directory",
    path: "/directory",
    component: require("@/pages/DirectoryPage").default,
  },
  {
    name: "Docs",
    path: "/docs",
    component: require("@/pages/DocsPage").default,
  },
  // Blog overview route
  {
    name: "Blog",
    path: "/blog",
    component: require("@/pages/BlogPage").default,
  },

  // Dynamic blog post route
  {
    path: "/blog/:slug",
    name: "BlogPost",
    component: BlogPost,
    props: true,
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy",
    component: require("@/pages/PrivacyPolicyPage").default,
  },
  {
    name: "Terms of Service",
    path: "/terms",
    component: require("@/pages/TermsPage").default,
  },
  // Conditionally add the Testing route
  ...(process.env.ENVIRONMENT !== "production"
    ? [
        {
          name: "Testing",
          path: "/testing",
          component: require("@/pages/TestingPage").default,
        },
      ]
    : []),
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: require("@/pages/404").default,
  },
];

export default routes;
