import { defineStore } from "pinia";

export const useSessionStore = defineStore("session", {
  state: () => ({
    user: null,
    theme: "cedrosdark",
    isSideNavCollapsed: false, // Add this line
  }),
  actions: {
    setUser(user) {
      this.user = user;
    },
    clearUser() {
      this.user = null;
    },
    setTheme(theme) {
      this.theme = theme;
    },
    setSideNavCollapsed(value) {
      // Add this method
      this.isSideNavCollapsed = value;
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.user,
  },
});
