<script setup>
import { useHead } from "@vueuse/head";
import VerifyEmailConfirmation from "@/components/login/VerifyEmailConfirmation.vue";

useHead({
  title: "Verify Your Email | Two Bros Inferencing",
  meta: [
    {
      name: "description",
      content: "Please verify your email.",
    },
    {
      property: "og:description",
      content: "Please verify your email.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});
</script>

<template>
  <div class="md:hero mx-auto p-4 w-full overflow-x-hidden">
    <div class="md:hero-content w-full flex flex-col mt-40">
      <VerifyEmailConfirmation />
    </div>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
