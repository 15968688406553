<script setup>
import { useHead } from "@vueuse/head";

useHead({
  title: "Pricing | Two Bros Inferencing | Choose the plan that suits you",
  meta: [
    {
      name: "description",
      content:
        "Discover our flexible pricing plans at Two Bros Inferencing. Choose from Developer, Business, or Enterprise plans to streamline your LLM integration.",
    },
    {
      property: "og:description",
      content:
        "Discover our flexible pricing plans at Two Bros Inferencing. Choose from Developer, Business, or Enterprise plans to streamline your LLM integration.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});
</script>

<template>
  <div
    class="relative container mx-auto max-w-screen-xl text-center py-20"
    style="min-height: 100vh"
  >
    <h1 class="text-4xl lg:text-6xl font-bold mb-12 mt-40 text-base-content">
      PRICING PLANS
    </h1>
    <p class="text-lg mb-12 max-w-2xl mx-auto text-base-content">
      Choose the plan that best fits your needs.
    </p>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      <!-- Developer Plan -->
      <div class="border rounded-lg p-6 flex flex-col">
        <h2 class="text-2xl font-semibold mb-4">DEVELOPER</h2>
        <p class="text-5xl font-bold mb-4">
          $29<span class="text-xl font-medium">/month</span>
        </p>
        <ul class="text-left mb-6 flex-grow">
          <li class="mb-2">1,000 Fast Lane API Calls</li>
          <li class="mb-2">Unlimited Slow Lane API Calls</li>
          <li class="mb-2">$0.20 per additional Fast Lane API Call</li>
        </ul>
        <button
          class="w-full px-6 py-3 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
        >
          Coming Soon
        </button>
      </div>
      <!-- Business Plan -->
      <div class="border rounded-lg p-6 flex flex-col">
        <h2 class="text-2xl font-semibold mb-4">BUSINESS</h2>
        <p class="text-5xl font-bold mb-4">
          $89<span class="text-xl font-medium">/month</span>
        </p>
        <ul class="text-left mb-6 flex-grow">
          <li class="mb-2">5,000 Fast Lane API Calls</li>
          <li class="mb-2">Unlimited Slow Lane API Calls</li>
          <li class="mb-2">$0.15 per additional Fast Lane API Call</li>
        </ul>
        <button
          class="w-full px-6 py-3 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
        >
          Coming Soon
        </button>
      </div>
      <!-- Enterprise Plan -->
      <div class="border rounded-lg p-6 flex flex-col">
        <h2 class="text-2xl font-semibold mb-4">ENTERPRISE</h2>
        <p class="text-5xl font-bold mb-4">
          $499<span class="text-xl font-medium">/month</span>
        </p>
        <ul class="text-left mb-6 flex-grow">
          <li class="mb-2">50,000 Fast Lane API Calls</li>
          <li class="mb-2">Unlimited Slow Lane API Calls</li>
          <li class="mb-2">$0.09 per additional Fast Lane API Call</li>
        </ul>
        <button
          class="w-full px-6 py-3 bg-neutral-focus text-base-content rounded hover:bg-base-100 transition duration-300"
        >
          Coming Soon
        </button>
      </div>
    </div>
    <div class="emptySpace mt-0 lg:mt-32"></div>
  </div>
</template>

<style scoped>
/* Additional styling if needed */
</style>
