<template>
  <div
    class="relative container mx-auto max-w-screen-xl text-center"
    style="min-height: 100vh"
  >
    <h1
      class="text-4xl lg:text-6xl font-bold max-w-2xl mx-auto mt-20 md:mt-42 mb-12 text-base-content text-center"
    >
      FAQ
    </h1>
    <FaqSection :faqs="faqs" />
    <div class="emptySpace mt-0 lg:mt-32"></div>
  </div>
</template>

<script setup>
import { useHead } from "@vueuse/head";
import FaqSection from "@/components/faq/FaqSection.vue";

useHead({
  title: "FAQ | Two Bros Inferencing",
  meta: [
    {
      name: "description",
      content:
        "Frequently asked questions about Two Bros Inferencing and integrating AI into your apps.",
    },
    {
      property: "og:description",
      content:
        "Frequently asked questions about Two Bros Inferencing and integrating AI into your apps.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});

const faqs = [
  {
    question: "What is Two Bros Inferencing?",
    answer:
      "Two Bros Inferencing is a developer platform that helps integrate Large Language Models (LLMs) into applications. It provides structure on top of raw LLM endpoints and aggregates inference services to assist in the development process.",
    learnMore: "/about",
    order: 1,
  },
  {
    question: "How does Two Bros Inferencing help developers?",
    answer:
      "We provide a platform that simplifies the integration of LLMs by offering an opinionated framework with built-in practices. This helps developers focus on building their applications without dealing with the complexities of LLM querying.",
    learnMore: "/docs/getting-started",
    order: 2,
  },
  {
    question: "What makes your platform opinionated?",
    answer:
      "Our platform adds structure over raw LLM endpoints. By enforcing certain conventions and workflows, developers can achieve consistent results, which may improve application performance and user experience.",
    learnMore: "/docs/platform-structure",
    order: 3,
  },
  {
    question: "How does the smart inference aggregation work?",
    answer:
      "We route RAG (Retrieval Augmented Generation) augmented user queries to cost-effective inference providers that meet our quality standards for speed and reliability.",
    learnMore: "/docs/inference-aggregation",
    order: 4,
  },
  {
    question: "What quality checks are performed on responses?",
    answer:
      "We provide basic quality checks that vary depending on the method selected in your API call. These checks aim to maintain consistency and reliability in the outputs delivered to your end-users.",
    learnMore: "/docs/quality-assurance",
    order: 5,
  },
  {
    question: "Can I customize the inference methods in API calls?",
    answer:
      "Yes, you can choose different methods and customize parameters in your API calls to meet your application's requirements.",
    learnMore: "/docs/api-reference",
    order: 6,
  },
  {
    question: "How do I get started with Two Bros Inferencing?",
    answer:
      "To get started, visit our documentation to learn how to integrate our platform into your application and utilize LLM capabilities.",
    learnMore: "/docs/getting-started",
    order: 7,
  },
  {
    question: "What are the pricing options?",
    answer:
      "We have various pricing plans available. Visit our pricing page for detailed information on the options.",
    learnMore: "/pricing",
    order: 8,
  },
  {
    question: "Do you support multiple LLM providers?",
    answer:
      "Yes, our platform aggregates multiple inference providers, providing access to various LLMs while maintaining quality and cost-effectiveness.",
    learnMore: "/docs/providers",
    order: 9,
  },
  {
    question: "How can I contact support?",
    answer:
      "You can contact us via the contact page or send an email to support@twobrosinferencing.com.",
    learnMore: "/contact",
    order: 10,
  },
];
</script>
