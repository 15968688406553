<!-- MobileMenu.vue -->
<template>
  <div>
    <!-- Overlay -->
    <div
      v-if="isOpen"
      class="fixed inset-0 bg-base-200 bg-opacity-80 z-50 transition-opacity duration-300"
      @click="closeMenu"
    ></div>

    <!-- Menu -->
    <div
      :class="[
        'fixed top-0 right-0 h-full w-64 bg-base-100 text-base-content z-50 transform transition-transform duration-300 ease-in-out',
        isOpen ? 'translate-x-0' : 'translate-x-full',
      ]"
    >
      <!-- Close button -->
      <button @click="closeMenu" class="absolute top-4 right-4 text-gray-600">
        <font-awesome-icon :icon="['fas', 'times']" class="text-2xl" />
      </button>

      <!-- Logo -->
      <router-link to="/" class="shrink-0" @click="closeMenu">
        <img
          src="/images/two_bros_inferencing_logo_large.svg"
          alt="Two Bros Inferencing"
          class="w-36 px-3 mt-5"
        />
      </router-link>

      <!-- Menu items -->
      <nav class="pt-16">
        <ul>
          <li
            v-for="(link, index) in links"
            :key="index"
            class="border-b border-base-content mx-4 pl-0 py-2"
          >
            <router-link
              :to="link.to"
              class="block px-2 py-2 text-base-content hover:text-secondary-focus hover:bg-neutral-focus hover:rounded-lg"
              @click="closeMenu"
            >
              {{ link.text }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
// import { ref } from "vue";

defineProps({
  isOpen: Boolean,
});

const emit = defineEmits(["close"]);

const links = [
  { to: "/pricing", text: "Pricing" },
  { to: "/docs", text: "Docs" },
  { to: "/blog", text: "Blog" },
];

const closeMenu = () => {
  emit("close");
};
</script>
