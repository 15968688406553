<!-- DocsPage.vue | @/pages/DocsPage.vue -->
<script setup>
import { ref } from "vue";
import { useHead } from "@vueuse/head";

useHead({
  title: "Documentation | Two Bros Inferencing",
  meta: [
    {
      name: "description",
      content:
        "Access the documentation for Two Bros Inferencing to learn how to integrate our platform into your applications.",
    },
    {
      property: "og:description",
      content:
        "Access the documentation for Two Bros Inferencing to learn how to integrate our platform into your applications.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});

const sections = [
  { title: "GETTING STARTED", content: "Content for Getting Started..." },
  { title: "METHODS", content: "Content for API Reference..." },
  { title: "ERRORS", content: "Content for Platform Structure..." },
  { title: "PROVIDERS", content: "Content for Providers..." },
  { title: "SUPPORT", content: "Content for Support..." },
];

const activeSection = ref(sections[0]);
</script>

<template>
  <div class="flex flex-col md:flex-row" style="min-height: 100vh">
    <!-- Sidebar Navigation -->
    <aside
      class="md:w-64 w-full md:h-screen md:sticky top-20 md:top-0 bg-base-100 p-6"
    >
      <div class="flex flex-col h-full justify-center">
        <h2 class="text-xl font-bold mb-6">SECTIONS</h2>
        <nav>
          <ul>
            <li
              v-for="section in sections"
              :key="section.title"
              @click="activeSection = section"
              class="mb-4 cursor-pointer"
            >
              <a
                :class="[
                  'text-lg',
                  activeSection.title === section.title
                    ? 'text-accent font-semibold text-sm'
                    : 'text-base-content text-sm',
                ]"
              >
                {{ section.title }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>

    <!-- Main Content Area -->
    <main class="flex-1 p-6 md:p-10 mt-16 md:mt-32">
      <h1 class="text-3xl font-bold mb-6">{{ activeSection.title }}</h1>
      <p class="text-base-content">{{ activeSection.content }}</p>
    </main>
  </div>
</template>

<style scoped>
/* Adjust the position of the sidebar navigation */
aside {
  border-right: 1px solid #e5e7eb; /* Tailwind's gray-200 */
}

/* Ensure the sidebar is vertically centered */
aside .flex-col {
  min-height: calc(
    100vh - 64px
  ); /* Adjust based on navbar height if necessary */
}

/* Adjust for mobile responsiveness */
@media (max-width: 768px) {
  aside {
    position: relative;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #e5e7eb;
  }

  aside .flex-col {
    min-height: auto;
    justify-content: flex-start;
  }
}
</style>
