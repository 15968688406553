<template>
  <div class="referrals-container">
    <h3 class="text-xl text-secondary text-center">
      Earn 3% of your referrals' subscription costs
    </h3>
    <!-- Referrals Info Section -->
    <section class="text-base-content mt-10">
      <div class="grid grid-cols-3 gap-6 max-w-4xl mx-auto">
        <div
          class="stat-item bg-base-200 p-6 rounded-lg shadow-md flex flex-col items-center"
        >
          <span class="stat-label text-lg font-semibold mb-2"
            >Rewards Earned</span
          >
          <span class="stat-value text-2xl font-bold">{{
            formatNumber(user.AffiliateRewardsEarned)
          }}</span>
          <router-link class="w-full" to="/dashboard/rewards">
            <button
              class="btn btn-sm mt-4 w-full"
              :class="{
                'bg-neutral hover:bg-neutral-focus': canClaim,
                'bg-base-300': !canClaim,
              }"
              :disabled="!canClaim"
            >
              View All Rewards
            </button>
          </router-link>
        </div>
        <div
          class="stat-item bg-base-200 p-6 rounded-lg shadow-md flex flex-col items-center"
        >
          <span class="stat-label text-lg font-semibold mb-2"
            >Active Referrals</span
          >
          <span class="stat-value text-2xl font-bold">{{
            formatNumber(user.activeReferrals)
          }}</span>
          <button class="btn btn-sm mt-4 w-full bg-base-300" disabled>
            Past Hour
          </button>
        </div>
        <div
          class="stat-item bg-base-200 p-6 rounded-lg shadow-md flex flex-col items-center"
        >
          <span class="stat-label text-lg font-semibold mb-2"
            >Total Referrals</span
          >
          <span class="stat-value text-2xl font-bold">{{
            formatNumber(user.totalReferrals)
          }}</span>
          <button class="btn btn-sm mt-4 w-full bg-base-300" disabled>
            All Time
          </button>
        </div>
      </div>
      <p class="text-center text-neutral mt-4">
        Referral information is updated every hour.
      </p>
    </section>

    <!-- Referral Code Section -->
    <div
      class="container mx-auto p-8 bg-base-200 w-135 rounded-lg shadow-md mt-20"
    >
      <h3 class="text-lg text-content text-center mb-10">
        Your Referral Codes
      </h3>
      <div
        v-for="(code, index) in affiliateCodes"
        :key="index"
        class="bg-base-100 p-4 rounded-lg shadow-md mb-4"
      >
        <div class="flex items-center justify-between">
          <span class="text-base-content">{{ code }}</span>
          <div class="flex space-x-2">
            <button @click="copyCode(code)" class="btn btn-ghost btn-sm px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </button>
            <button
              @click="showDeleteConfirmModal(code)"
              class="btn btn-ghost btn-sm px-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        class="flex space-x-4 w-full justify-center mt-10"
        v-if="affiliateCodes.length < 5"
      >
        <button
          @click="showAddCodeModal"
          class="w-46 px-4 py-2 text-base-content rounded transition duration-300 bg-neutral hover:bg-neutral-focus"
        >
          Add New Code
        </button>
      </div>
    </div>

    <!-- Add Code Modal -->
    <dialog ref="addCodeModal" class="modal">
      <div class="modal-box bg-base-100">
        <h2 class="font-bold text-2xl">Add New Referral Code</h2>
        <input
          v-model="newCode"
          type="text"
          placeholder="Enter new referral code"
          class="input input-bordered w-full mt-4"
        />
        <div class="modal-action flex justify-between mt-4">
          <button
            @click="closeAddCodeModal"
            class="w-46 px-4 py-2 text-base-content rounded transition duration-300 bg-neutral hover:bg-neutral-focus"
          >
            Cancel
          </button>
          <button
            @click="addNewCode"
            class="w-46 px-4 py-2 text-base-content rounded transition duration-300 bg-primary hover:bg-primary-focus"
          >
            Add Code
          </button>
        </div>
      </div>
    </dialog>

    <!-- Delete Confirmation Modal -->
    <dialog ref="deleteConfirmModal" class="modal">
      <div class="modal-box bg-base-100">
        <h2 class="font-bold text-2xl">Confirm Deletion</h2>
        <p class="py-4">
          Are you sure you want to delete this referral code? This action cannot
          be undone.
        </p>
        <div class="modal-action flex justify-between mt-4">
          <button
            @click="closeDeleteConfirmModal"
            class="w-46 px-4 py-2 text-base-content rounded transition duration-300 bg-neutral hover:bg-neutral-focus"
          >
            Cancel
          </button>
          <button
            @click="confirmDeleteCode"
            class="w-46 px-4 py-2 text-base-content rounded transition duration-300 bg-primary hover:bg-primary-focus"
          >
            Delete
          </button>
        </div>
      </div>
    </dialog>

    <!-- Archive Section -->
    <section class="archive text-base-content p-8 mt-20">
      <h2>Referral Earnings</h2>
      <div class="grid grid-cols-4 gap-4">
        <div
          v-for="epoch in epochs"
          :key="epoch"
          class="card bg-base-200 shadow-xl cursor-pointer"
          :class="{ 'border-2 border-primary': epoch === activeEpoch }"
          @click="selectEpoch(epoch)"
        >
          <div class="card-body">
            <h2 class="card-title">Epoch {{ epoch }}</h2>
          </div>
        </div>
      </div>
    </section>

    <!-- Referrals History Section -->
    <section class="text-base-content p-8">
      <div class="bg-base-200 rounded-lg shadow-md p-8">
        <h3 class="text-2xl">Epoch {{ activeEpoch }}</h3>
        <table
          class="table-auto w-full text-sm text-base-content text-left"
          v-if="referralsHistory.length > 0"
        >
          <thead>
            <tr>
              <th>Submission Time</th>
              <th>Your Earnings</th>
              <th>Referral Earnings</th>
              <th>Active Referrals</th>
              <th>Total Referrals</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, index) in referralsHistory" :key="index">
              <td>{{ formatDate(record.review_time) }}</td>
              <td>{{ record.affiliate_earnings }}</td>
              <td>{{ record.referral_earnings }}</td>
              <td>{{ record.active_referrals }}</td>
              <td>{{ record.total_referrals }}</td>
            </tr>
          </tbody>
        </table>
        <p v-else>
          Your referrals did not submit any solutions during this epoch.
        </p>

        <!-- Pagination -->
        <div class="flex justify-between mt-4" v-if="totalRecords > 0">
          <button
            class="btn btn-sm"
            @click="changePage(-1)"
            :disabled="currentPage === 0"
          >
            Previous
          </button>
          <span>Page {{ currentPage + 1 }} of {{ totalPages }}</span>
          <button
            class="btn btn-sm"
            @click="changePage(1)"
            :disabled="currentPage >= totalPages - 1"
          >
            Next
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useSessionStore } from "@/stores/session";
import { toast } from "vue3-toastify";
const sessionStore = useSessionStore();
const userApiKey = sessionStore.user.apiKey;
const user = computed(() => sessionStore.user);
const affiliateCodes = ref([]);
const addCodeModal = ref(null);
const newCode = ref("");
const deleteConfirmModal = ref(null);
const codeToDelete = ref("");

const referralsInfo = ref({
  affiliateRewardsEarned: 0,
  activeReferrals: 0,
  totalReferrals: 0,
});

const totalRecords = ref(0);
const itemsPerPage = 20;
const totalPages = computed(() => Math.ceil(totalRecords.value / itemsPerPage));

const noSubmissionsMessage = ref("No submissions during this epoch");
const referralsHistory = ref([]);
const currentEpoch = ref(0);
let countdownInterval;
let updateInterval;

const toastTheme = computed(() =>
  sessionStore.theme === "customdark" ? "dark" : "light"
);

const activeEpoch = ref(0);
const currentPage = ref(0);
const epochs = computed(() => {
  const epochArray = [];
  for (let i = currentEpoch.value; i >= 1; i--) {
    epochArray.push(i);
  }
  return epochArray;
});

const canClaim = computed(() => user.value.AffiliateRewardsEarned > 0);

// Calculate current epoch
const calculateCurrentEpoch = () => {
  const epochStartDate = new Date("2024-08-01T00:00:00Z");
  const now = new Date();
  const elapsed = now - epochStartDate;
  const epochDuration = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  return Math.floor(elapsed / epochDuration);
};

const fetchWithRetry = async (url, options, retries = 3) => {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      if (i === retries - 1) throw error;
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before retrying
    }
  }
};

const fetchReferralsInfo = async (userApiKey) => {
  if (!userApiKey) return;
  try {
    const data = await fetchWithRetry(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/affiliate-rewards-info`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": userApiKey,
        },
        credentials: "include",
      }
    );
    referralsInfo.value = data;
    sessionStorage.setItem("referralsInfo", JSON.stringify(data));
  } catch (error) {
    console.error("Error fetching affiliate rewards info:", error);
    const storedData = sessionStorage.getItem("referralsInfo");
    if (storedData) {
      referralsInfo.value = JSON.parse(storedData);
      console.log("Using stored referrals info data");
    }
  }
};

const fetchReferralsHistory = async (
  userApiKey,
  epoch = currentEpoch.value,
  page = 0
) => {
  if (!userApiKey) return;
  try {
    const data = await fetchWithRetry(
      `${
        process.env.VUE_APP_BACKEND_API_URL
      }/users/affiliate-rewards-history?epoch=${epoch}&offset=${
        page * itemsPerPage
      }&limit=${itemsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": userApiKey,
        },
        credentials: "include",
      }
    );
    referralsHistory.value = data.results;
    totalRecords.value = data.total;
    noSubmissionsMessage.value = "No submissions during this epoch";
    sessionStorage.setItem(
      `referralsHistory_${epoch}_${page}`,
      JSON.stringify(data)
    );
  } catch (error) {
    console.error("Error fetching affiliate rewards history:", error);
    const storedData = sessionStorage.getItem(
      `referralsHistory_${epoch}_${page}`
    );
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      referralsHistory.value = parsedData.results;
      totalRecords.value = parsedData.total;
      console.log("Using stored affiliate rewards history data");
    } else {
      referralsHistory.value = [];
      totalRecords.value = 0;
      noSubmissionsMessage.value =
        "Failed to fetch affiliate rewards history. Please try again later.";
    }
  }
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString();
};

const formatNumber = (number) => {
  if (number < 1000) {
    return number.toString();
  } else if (number < 100000) {
    return (number / 1000).toFixed(2) + "K";
  } else if (number < 1000000) {
    return (number / 1000).toFixed(1) + "K";
  } else if (number < 10000000) {
    return (number / 1000000).toFixed(2) + "M";
  } else if (number < 1000000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else {
    return (number / 1000000000).toFixed(1) + "B";
  }
};

const copyCode = (code) => {
  navigator.clipboard.writeText(`${window.location.origin}?aff=${code}`);
  toast.success("Affiliate url copied to clipboard", {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
    theme: toastTheme.value,
  });
};

const showDeleteConfirmModal = (code) => {
  codeToDelete.value = code;
  deleteConfirmModal.value.showModal();
};

const closeDeleteConfirmModal = () => {
  deleteConfirmModal.value.close();
  codeToDelete.value = "";
};

const confirmDeleteCode = async () => {
  await deleteCode(codeToDelete.value);
  closeDeleteConfirmModal();
};

const deleteCode = async (code) => {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/affiliate-codes`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": user.value.apiKey,
        },
        body: JSON.stringify({ code }),
        credentials: "include",
      }
    );

    if (response.ok) {
      affiliateCodes.value = affiliateCodes.value.filter((c) => c !== code);
      toast.success("Referral code deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        theme: toastTheme.value,
      });
    } else {
      toast.error("Failed to delete referral code", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        theme: toastTheme.value,
      });
    }
  } catch (error) {
    console.error("Error deleting code:", error);
    toast.error("An error occurred while deleting the referral code", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      theme: toastTheme.value,
    });
  }
};

const showAddCodeModal = () => {
  addCodeModal.value.showModal();
};

const closeAddCodeModal = () => {
  addCodeModal.value.close();
  newCode.value = "";
};

const addNewCode = async () => {
  if (newCode.value.trim() === "") return;

  try {
    const response = await fetch(
      `${process.env.VUE_APP_BACKEND_API_URL}/users/affiliate-codes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": user.value.apiKey,
        },
        body: JSON.stringify({ code: newCode.value }),
        credentials: "include",
      }
    );

    if (response.ok) {
      affiliateCodes.value.push(newCode.value);
      closeAddCodeModal();
      toast.success("Referral code added successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        theme: toastTheme.value,
      });
    } else if (response.status === 409) {
      toast.error("This referral code is already in use", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        theme: toastTheme.value,
      });
    } else {
      toast.error("Failed to add new referral code", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        theme: toastTheme.value,
      });
    }
  } catch (error) {
    console.error("Error adding new code:", error);
    toast.error("An error occurred while adding the referral code", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      theme: toastTheme.value,
    });
  }
};

const selectEpoch = (epoch) => {
  activeEpoch.value = epoch;
  currentPage.value = 0;
  fetchReferralsHistory(userApiKey, epoch);
};

const changePage = (direction) => {
  const newPage = currentPage.value + direction;
  if (newPage >= 0 && newPage < totalPages.value) {
    currentPage.value = newPage;
    fetchReferralsHistory(userApiKey, activeEpoch.value, currentPage.value);
  }
};

onMounted(() => {
  currentEpoch.value = calculateCurrentEpoch();
  activeEpoch.value = currentEpoch.value;

  affiliateCodes.value = user.value.affiliateCodes || [];

  // Try to load initial data from session storage
  const storedReferralsInfo = sessionStorage.getItem("referralsInfo");
  const storedReferralsHistory = sessionStorage.getItem(
    `referralsHistory_${currentEpoch.value}_0`
  );

  if (storedReferralsInfo) {
    referralsInfo.value = JSON.parse(storedReferralsInfo);
    fetchReferralsInfo(userApiKey);
  } else {
    fetchReferralsInfo(userApiKey);
  }

  if (storedReferralsHistory) {
    const parsedData = JSON.parse(storedReferralsHistory);
    referralsHistory.value = parsedData.results;
    totalRecords.value = parsedData.total;
  } else {
    fetchReferralsHistory(userApiKey);
  }

  // Set up automatic updates every 60 seconds
  updateInterval = setInterval(() => {
    fetchReferralsInfo(userApiKey);
    fetchReferralsHistory(userApiKey, activeEpoch.value, currentPage.value);
  }, 60000);
});

onUnmounted(() => {
  if (countdownInterval) clearInterval(countdownInterval);
  if (updateInterval) clearInterval(updateInterval);
});

// Watch for changes in userApiKey
watch(
  () => sessionStore.user.apiKey,
  (newApiKey) => {
    if (newApiKey) {
      fetchReferralsInfo(newApiKey);
      fetchReferralsHistory(newApiKey, activeEpoch.value, currentPage.value);
    }
  }
);
</script>

<style scoped>
.referrals-container {
  min-height: 110vh;
}

.stat-item {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}
</style>
