<template>
  <div class="dropdown dropdown-top dropdown-end hidden xl:block">
    <label tabindex="0" class="btn btn-circle m-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        />
      </svg>
    </label>
    <ul
      tabindex="0"
      class="dropdown-content menu p-2 shadow bg-neutral text-base-content rounded-box w-40 max-h-80 overflow-y-auto"
    >
      <li v-for="theme in themes" :key="theme" class="">
        <a
          @click="changeTheme(theme)"
          :class="[
            session.theme === theme
              ? 'bg-base-200 text-base-content hover:bg-base-200 hover:text-base-content'
              : 'hover:bg-neutral-focus hover:text-base-content',
          ]"
        >
          {{ theme.replace("cedros", "") + " mode" }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useSessionStore } from "@/stores/session";
import axios from "axios";
import { ref, computed } from "vue";

const session = useSessionStore();

const themes = ref([
  "cedrosdark",
  "cedroslight",
  //   "light",
  //   "dark",
  //   "cupcake",
  //   "bumblebee",
  //   "emerald",
  //   "corporate",
  //   "synthwave",
  //   "retro",
  //   "cyberpunk",
  //   "valentine",
  //   "halloween",
  //   "garden",
  //   "forest",
  //   "aqua",
  //   "lofi",
  //   "pastel",
  //   "fantasy",
  //   "wireframe",
  //   "black",
  //   "luxury",
  //   "dracula",
  //   "cmyk",
  //   "autumn",
  //   "business",
  //   "acid",
  //   "lemonade",
  //   "night",
  //   "coffee",
  //   "winter",
]);

const isLoggedIn = computed(() => session.isLoggedIn);

const changeTheme = async (theme) => {
  session.setTheme(theme);

  // Check if the user is logged in
  if (isLoggedIn.value) {
    try {
      // Make an API call to update the theme in the database
      await axios.post(
        `${process.env.VUE_APP_BACKEND_API_URL}/users/update-info`,
        { theme: theme },
        { withCredentials: true }
      );
      // console.log("Theme updated successfully in the database");
    } catch (error) {
      console.error("Error updating theme in the database:", error);
      // Optionally, you can add error handling here, such as showing a notification to the user
    }
  }
};
</script>
