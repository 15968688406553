<script setup>
import { ref, computed, watch, h } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useLearnStore } from "@/stores/learnStore";
import { useHead } from "@vueuse/head";

const props = defineProps({ pathId: String });
const route = useRoute();
const router = useRouter();
const learnStore = useLearnStore();
const currentLessonIndex = ref(parseInt(route.query.slide, 10) - 1 || 0);

// Fetch the correct course based on the path ID
const currentCourse = computed(() => {
  return learnStore.courses.find(
    (course) =>
      course.courseTitle.toLowerCase().replace(/\s+/g, "-") === props.pathId
  );
});

const currentLesson = computed(() => {
  return currentCourse.value
    ? currentCourse.value.lessons[currentLessonIndex.value]
    : null;
});

const courseTitle = computed(() =>
  currentCourse.value ? currentCourse.value.courseTitle : "Learn"
);
const lessonTitle = computed(() =>
  currentLesson.value ? currentLesson.value.sectionTitle : "Lesson"
);
const lessonDescription = computed(() =>
  currentLesson.value
    ? currentLesson.value.intro
    : "Get started with the Solana ecosystem by choosing a learning path right for your knowledge level."
);

useHead({
  title: computed(
    () => `${lessonTitle.value} | ${courseTitle.value}  | Two Bros Inferencing`
  ),
  meta: [
    {
      property: "og:title",
      content: computed(
        () =>
          `${lessonTitle.value} | ${courseTitle.value}  | Two Bros Inferencing`
      ),
    },
    {
      name: "description",
      content: lessonDescription.value,
    },
    {
      property: "og:description",
      content: lessonDescription.value,
    },
  ],
});

const parseMarkdownLinks = (text) => {
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)(\*)?/g;
  return text.replace(linkRegex, (match, linkText, linkUrl, isExternal) => {
    if (isExternal) {
      return `<a class="text-secondary-focus hover:text-primary-focus" href="${linkUrl}" target="_blank" rel="noopener nofollow">${linkText}</a>`;
    } else {
      return `<a class="text-secondary-focus hover:text-primary-focus" href="${linkUrl}">${linkText}</a>`;
    }
  });
};

const renderedIntro = computed(() => {
  if (!currentLesson.value || !currentLesson.value.intro) return null;
  return h("div", { innerHTML: parseMarkdownLinks(currentLesson.value.intro) });
});

const renderedKeyPoints = computed(() => {
  if (!currentLesson.value || !currentLesson.value.keyPoints) return [];
  return currentLesson.value.keyPoints.map((keyPoint) =>
    h("li", { innerHTML: parseMarkdownLinks(keyPoint) })
  );
});

const renderedLearnMore = computed(() => {
  if (!currentLesson.value || !currentLesson.value.learnMore) return [];
  return currentLesson.value.learnMore.map((item) =>
    h("li", { innerHTML: parseMarkdownLinks(item) })
  );
});

// Watch the route to update the lesson index
watch(
  () => route.query.slide,
  (newSlide, oldSlide) => {
    if (newSlide !== oldSlide) {
      currentLessonIndex.value = parseInt(newSlide, 10) - 1;
    }
  }
);

const navigateToSlide = (slideNumber) => {
  router.push({
    name: "LearnPath",
    params: { pathId: props.pathId },
    query: { slide: slideNumber },
  });
};

const goToNextLesson = () => {
  if (currentLessonIndex.value < currentCourse.value.lessons.length - 1) {
    navigateToSlide(currentLessonIndex.value + 2);
  } else {
    // Navigate to the course completion screen
    router.push({
      name: "LearnPathComplete",
      params: { pathId: props.pathId },
    });
  }
};

const goToPreviousLesson = () => {
  if (currentLessonIndex.value > 0) {
    navigateToSlide(currentLessonIndex.value);
  }
};
</script>

<template>
  <div class="learn-path max-w-full mx-auto overflow-x-hidden">
    <div class="lesson-card" v-if="currentLesson">
      <h2 class="text-3xl lg:text-4xl">{{ currentLesson.sectionTitle }}</h2>
      <div
        class="text-base-content mt-4"
        v-if="renderedIntro"
        :key="`intro-${currentLessonIndex}`"
      >
        <component :is="renderedIntro" />
      </div>
      <h3
        class="text-base-content mt-8"
        v-if="currentLesson.keyPoints.length > 0"
      >
        Key Points
      </h3>
      <ul class="text-base-content" v-if="currentLesson.keyPoints.length > 0">
        <template
          v-for="(item, index) in renderedKeyPoints"
          :key="`keypoint-${index}`"
        >
          <li v-if="typeof item === 'string'">{{ item }}</li>
          <component :is="item" v-else />
        </template>
      </ul>
      <div class="flex justify-center">
        <h3 v-if="currentLesson.video !== ''" class="mt-8">Related Video</h3>
      </div>
      <div class="flex justify-center">
        <iframe
          v-if="currentLesson.video !== ''"
          class="hidden xl:block"
          width="760"
          height="428"
          :src="currentLesson.video"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>

        <iframe
          v-if="currentLesson.video !== ''"
          class="hidden lg:block xl:hidden"
          width="380"
          height="214"
          :src="currentLesson.video"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>

        <iframe
          v-if="currentLesson.video !== ''"
          class="block lg:hidden"
          width="310"
          height="175"
          :src="currentLesson.video"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>

      <h3 v-if="currentLesson.learnMore.length > 0" class="mt-8">Learn More</h3>
      <ul class="text-base-content" v-if="currentLesson.learnMore.length > 0">
        <template
          v-for="(item, index) in renderedLearnMore"
          :key="`learnmore-${index}`"
        >
          <li v-if="typeof item === 'string'">{{ item }}</li>
          <component :is="item" v-else />
        </template>
      </ul>
      <!-- Handle video and learn more links -->
      <div class="flex align-center justify-between mt-8">
        <button
          class="btn btn-base-200 outline-none border-neutral-focus text-base-content rounded bg-neutral-focus hover:bg-base-100 hover:border-base-100 transition duration-300"
          :class="{ 'btn-disabled': currentLessonIndex === 0 }"
          @click="goToPreviousLesson"
          :disabled="currentLessonIndex === 0"
        >
          Go Back
        </button>
        <button
          class="btn btn-base-100 mx-1 outline-none text-base-content rounded bg-primary border-primary hover:bg-primary-focus hover:border-primary-focus transition duration-300"
          @click="goToNextLesson"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  @apply text-base-content;
}

h3 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-family: "Lato-Bold", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}

ul {
  list-style-type: disc; /* Default bullet points */
  margin-left: 1rem; /* Adjust as needed */
}

li {
  margin-bottom: 0.5rem; /* Spacing between list items */
}
</style>
