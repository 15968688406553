<template>
  <div class="relative" ref="loginButtonRef">
    <!-- Profile picture or login button -->
    <template v-if="isLoggedIn">
      <div
        @mouseenter="showDropdown = true"
        @mouseleave="startHideDropdown"
        class="relative"
      >
        <img
          :src="profileImageUrl"
          class="w-10 h-10 rounded-full cursor-pointer object-cover"
          alt="Profile"
        />
        <!-- User dropdown menu -->
        <div
          v-if="showDropdown"
          @mouseenter="cancelHideDropdown"
          @mouseleave="startHideDropdown"
          class="absolute right-0 mt-2 w-48 dropdown-content menu p-2 shadow bg-neutral text-base-content rounded-box overflow-y-auto"
        >
          <router-link
            to="/dashboard"
            class="block px-4 py-2 text-sm text-base-content hover:bg-neutral-focus hover:text-base-content rounded-lg"
          >
            Dashboard
          </router-link>
          <router-link
            to="/profile"
            class="block px-4 py-2 text-sm text-base-content hover:bg-neutral-focus hover:text-base-content rounded-lg"
          >
            Profile
          </router-link>
          <router-link
            to="/settings"
            class="block px-4 py-2 text-sm text-base-content hover:bg-neutral-focus hover:text-base-content rounded-lg"
          >
            Settings
          </router-link>
          <a
            href="#"
            @click.prevent="handleLogout"
            class="block px-4 py-2 text-sm text-base-content hover:bg-neutral-focus hover:text-base-content rounded-lg"
          >
            Logout
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Login button -->
      <button
        @click="handleLoginClick"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        class="px-8 py-2 text-sm font-medium text-base-content hover:text-base-content border border-base-content hover:border-base-content hover:bg-base-200 rounded-lg"
      >
        Sign In
      </button>

      <!-- Modal for XL screens and above -->
      <div
        v-if="showModal"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @click="handleModalClick"
        class="absolute right-0 mt-0 pt-0 w-96 rounded-md z-20 hidden xl:block"
      >
        <div class="p-4">
          <LoginModal />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useSessionStore } from "@/stores/session";
import LoginModal from "./LoginModal.vue";
// import axios from "axios";

export default {
  components: {
    LoginModal,
  },
  setup() {
    const router = useRouter();
    const sessionStore = useSessionStore();
    const showModal = ref(false);
    const isModalClicked = ref(false);
    const loginButtonRef = ref(null);
    const showDropdown = ref(false);
    let hideDropdownTimeout = null;

    const isLoggedIn = computed(() => sessionStore.isLoggedIn);
    const profileImageUrl = computed(() => {
      return (
        sessionStore.user?.profileImageUrl ||
        "https://firebasestorage.googleapis.com/v0/b/cedros-images.appspot.com/o/profiles%2Fdefault.jpg?alt=media&token=923ca9e3-babf-4c07-9945-14b3b3ba9a9c"
      );
    });

    const handleLoginClick = () => {
      if (window.innerWidth < 1280) {
        router.push("/login");
      } else {
        showModal.value = true;
        isModalClicked.value = true;
      }
    };

    const handleMouseEnter = () => {
      if (window.innerWidth >= 1280) {
        showModal.value = true;
      }
    };

    const handleMouseLeave = () => {
      if (!isModalClicked.value && window.innerWidth >= 1280) {
        showModal.value = false;
      }
    };

    const handleModalClick = () => {
      isModalClicked.value = true;
    };

    const startHideDropdown = () => {
      hideDropdownTimeout = setTimeout(() => {
        showDropdown.value = false;
      }, 300); // 300ms delay before hiding
    };

    const cancelHideDropdown = () => {
      if (hideDropdownTimeout) {
        clearTimeout(hideDropdownTimeout);
      }
    };

    const handleClickOutside = (event) => {
      if (
        loginButtonRef.value &&
        !loginButtonRef.value.contains(event.target)
      ) {
        showModal.value = false;
        isModalClicked.value = false;
        showDropdown.value = false;
      }
    };

    const handleLogout = async () => {
      const baseUrl = process.env.VUE_APP_BACKEND_API_URL;
      try {
        const response = await fetch(`${baseUrl}/auth/logout`, {
          method: "GET",
          credentials: "include", // Include cookies in the request
        });

        if (!response.ok) {
          throw new Error("Failed to logout");
        }

        sessionStore.clearUser();

        // Redirect to the homepage or another page after logout
        window.location.href = "/";
      } catch (error) {
        console.error("Error during logout:", error);
        alert("An error occurred while logging out. Please try again.");
      }
    };

    const closeModal = () => {
      showModal.value = false;
      isModalClicked.value = false;
    };

    // Watch for route changes
    watch(
      () => router.currentRoute.value.path,
      (newPath) => {
        if (["/welcome", "/verify-email", "/dashboard"].includes(newPath)) {
          closeModal();
        }
      }
    );

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
      if (hideDropdownTimeout) {
        clearTimeout(hideDropdownTimeout);
      }
    });

    return {
      showModal,
      closeModal,
      isModalClicked,
      handleLoginClick,
      handleMouseEnter,
      handleMouseLeave,
      handleModalClick,
      loginButtonRef,
      isLoggedIn,
      profileImageUrl,
      showDropdown,
      startHideDropdown,
      cancelHideDropdown,
      handleLogout,
    };
  },
};
</script>
