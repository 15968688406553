<script setup>
import { ref, watch, computed, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  matchesSearchBlog,
  initializeFromQueryBlog,
  updateQueryParametersBlog,
} from "@/utilities/directory/functions";
import { useBlogStore } from "@/stores/blogStore";
import { useSessionStore } from "@/stores/session";
import { useBookmarkStore } from "@/stores/bookmarkStore";
import { storeToRefs } from "pinia";
import BlogCard from "./BlogCard.vue"; // Import the new BlogCard component

const route = useRoute();
const router = useRouter();
const blogStore = useBlogStore();
const blogPosts = ref([]);

const sessionStore = useSessionStore();
const bookmarkStore = useBookmarkStore();
const { isLoggedIn } = storeToRefs(sessionStore);
// const { bookmarks } = storeToRefs(bookmarkStore);

const selectedCategory = ref("All");
const categories = blogStore.categories;
const activeCategories = blogStore.activeCategories;
const activeSortOption = ref(route.query.sort || "Newest");
const searchQuery = ref(route.query.search || "");
const selectedKnowledgeLevel = ref(route.query.knowledgeLevel || "All");

// Pagination
const currentPage = ref(parseInt(route.query.page) || 1);
const itemsPerPage = 20;

const selectSingleCategory = (category) => {
  if (category === "All") {
    blogStore.setActiveCategories("All");
  } else {
    blogStore.activeCategories.clear();
    blogStore.activeCategories.add(category);
  }
  selectedCategory.value = category;
  updateQueryParametersBlog(
    router,
    route,
    blogStore.activeCategories,
    selectedKnowledgeLevel.value
  );
};

const toggleAllCategories = () => {
  selectSingleCategory("All");
};

watch(
  () => blogStore.activeCategories,
  (newActiveCategories) => {
    if (newActiveCategories.size === categories.length) {
      selectedCategory.value = "All";
    } else if (newActiveCategories.size === 1) {
      selectedCategory.value = Array.from(newActiveCategories)[0];
    }
  },
  { deep: true }
);

watch(
  route,
  () => {
    initializeFromQueryBlog(
      route.query,
      blogStore,
      activeSortOption,
      selectedKnowledgeLevel
    );
  },
  { immediate: true }
);

const updateSortOption = (option) => {
  activeSortOption.value = option;
  router.push({ query: { ...route.query, sort: option } });
};

const filteredItems = computed(() => {
  let items = blogStore.blogPosts.filter((post) => {
    const categoryMatch =
      blogStore.activeCategories.has("All") ||
      [...blogStore.activeCategories].some((category) =>
        post.Categories.includes(category)
      );
    let knowledgeLevelMatch = blogStore.selectedKnowledgeLevel === "All";
    if (!knowledgeLevelMatch && post.KnowledgeLevel) {
      knowledgeLevelMatch =
        parseInt(post.KnowledgeLevel) === blogStore.selectedKnowledgeLevel;
    }

    return (
      matchesSearchBlog(post, searchQuery.value) &&
      categoryMatch &&
      knowledgeLevelMatch
    );
  });

  return sortItems(items, activeSortOption.value);
});

function sortItems(items, sortMethod) {
  switch (sortMethod) {
    case "A-Z":
      return items.sort((a, b) => a.Title.localeCompare(b.Title));
    case "Z-A":
      return items.sort((a, b) => b.Title.localeCompare(a.Title));
    case "Newest":
    default:
      return items.sort(
        (a, b) => new Date(b.UpdatedAt) - new Date(a.UpdatedAt)
      );
  }
}

const handleSearch = (event) => {
  searchQuery.value = event.target.value;
};

const isActiveAllCategories = computed(
  () => activeCategories.size === categories.length
);

const setKnowledgeLevel = (level) => {
  blogStore.setKnowledgeLevel(level);
};

const knowledgeLevelMap = {
  All: "All",
  Beginner: 1,
  Intermediate: 2,
  Advanced: 3,
};

const isSelectedKnowledgeLevel = (level) => {
  const numericLevel = knowledgeLevelMap[level];
  return blogStore.selectedKnowledgeLevel === numericLevel;
};

// New computed property for paginated items
const paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return filteredItems.value.slice(startIndex, endIndex);
});

// New computed property for total pages
const totalPages = computed(() => {
  return Math.ceil(filteredItems.value.length / itemsPerPage);
});

// New method to change page
const changePage = (page) => {
  currentPage.value = page;
  router.push({ query: { ...route.query, page } });
};

onMounted(async () => {
  await blogStore.fetchBlogPosts();
  blogPosts.value = blogStore.blogPosts;
  window.addEventListener("keydown", handleKeyDown);
  // Fetch bookmarks if user is logged in
  if (isLoggedIn.value) {
    await bookmarkStore.getUserBookmarks();
  }
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyDown);
});

// Watch for changes in route query
watch(
  () => route.query,
  () => {
    currentPage.value = parseInt(route.query.page) || 1;
  }
);

// Watch for changes in login status
watch(isLoggedIn, async (newValue) => {
  if (newValue) {
    await bookmarkStore.getUserBookmarks();
  } else {
    // Clear bookmarks when user logs out
    bookmarkStore.$reset();
  }
});

// Function to handle the keyboard shortcut
const handleKeyDown = (event) => {
  if ((event.metaKey || event.ctrlKey) && event.key === "k") {
    event.preventDefault();
    document.getElementById("search-input").focus();
  }
};

// Function to check if a post is bookmarked
const isBookmarked = (postId) => {
  return bookmarkStore.isBookmarked(postId);
};
</script>

<template>
  <div class="container mx-auto p-4 md:my-12">
    <div class="max-w-180 mx-auto">
      <!-- Top Row for Search and Sort -->
      <div class="hidden md:flex justify-between items-center mb-4">
        <!-- Search Bar -->
        <div class="relative w-full lg:w-1/2">
          <font-awesome-icon
            class="absolute left-3 top-1/2 transform -translate-y-1/2 text-base-content"
            :icon="['fas', 'magnifying-glass']"
          />
          <input
            type="text"
            placeholder="Search..."
            id="search-input"
            class="input input-bordered pl-10 w-80 bg-base-200 text-base-content placeholder-base-content"
            v-model="searchQuery"
            @input="handleSearch"
          />
        </div>
        <!-- Sort Dropdown -->
        <div class="dropdown dropdown-end text-base-content">
          <label
            tabindex="0"
            class="btn m-1 text-base-content bg-base-200 hover:bg-base-100 w-32"
          >
            {{ activeSortOption }}
          </label>
          <ul
            tabindex="0"
            class="dropdown-content z-[1] custom-menu p-2 shadow bg-base-100 rounded-box w-52 text-base-content"
          >
            <li
              id="blog-sort-option-1"
              class="hover:text-primary hover:bg-neutral rounded hover:cursor-pointer"
              :class="{
                'font-bold text-primary-focus': activeSortOption === 'Newest',
              }"
            >
              <a @click="updateSortOption('Newest')">Newest</a>
            </li>
            <li
              id="blog-sort-option-2"
              class="hover:text-primary hover:bg-neutral rounded hover:cursor-pointer"
              :class="{
                'font-bold text-primary-focus': activeSortOption === 'A-Z',
              }"
            >
              <a @click="updateSortOption('A-Z')">A-Z</a>
            </li>
            <li
              id="blog-sort-option-3"
              class="hover:text-primary hover:bg-neutral rounded hover:cursor-pointer"
              :class="{
                'font-bold text-primary-focus': activeSortOption === 'Z-A',
              }"
            >
              <a @click="updateSortOption('Z-A')">Z-A</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Knowledge Level Buttons -->
      <div class="mb-4 flex flex-wrap justify-center md:justify-start">
        <button
          v-for="level in ['All', 'Beginner', 'Intermediate', 'Advanced']"
          :key="level"
          @click="setKnowledgeLevel(level)"
          :class="[
            'knowledge-level-button',
            {
              'active-class': isSelectedKnowledgeLevel(level),
              'inactive-class': !isSelectedKnowledgeLevel(level),
            },
          ]"
        >
          {{ level }}
        </button>
      </div>
      <!-- Category Buttons -->
      <!-- All Categories Button -->
      <div class="hidden md:flex flex-wrap">
        <button
          @click="toggleAllCategories"
          class="h-8 w-32 py-1 px-2 rounded-full m-1 hover:border-secondary category-select-button"
          :class="{
            'active-class': isActiveAllCategories,
            'inactive-class': !isActiveAllCategories,
          }"
        >
          All Categories
        </button>
        <button
          v-for="category in categories"
          :key="category"
          @click="selectSingleCategory(category)"
          class="h-8 w-24 py-1 px-2 rounded-full m-1 hover:border-secondary category-select-button"
          :class="{
            'active-class': activeCategories.has(category),
            'inactive-class': !activeCategories.has(category),
          }"
        >
          {{ category }}
        </button>
      </div>
    </div>

    <!-- Blog Posts Display -->
    <!-- <div class="blog-posts-grid">
      <div
        v-for="post in paginatedItems"
        :key="post.ID"
        class="blog-post-card mt-24 px-8 pb-6 bg-base-200 text-base-content shadow-lg rounded-lg w-full mx-auto"
      >
        <div class="flex justify-center md:justify-end -mt-8 w-full">
          <router-link
            :to="'/blog/' + post.Slug"
            class="w-full h-40 rounded-lg border-2 border-neutral p-0 m-0 -mt-4"
            ><img
              class="w-full h-40 object-cover p-0 m-0 rounded-lg"
              :src="post.FeaturedImage"
              alt=""
          /></router-link>
        </div>
        <div class="mt-8 flex flex-col h-full">
          <div>
            <h2 class="text-base-content text-3xl font-semibold">
              {{ post.Title }}
            </h2>
            <p class="mt-2 text-base-content">
              {{ post.Intro ? post.Intro.substring(0, 100) : "" }}...
            </p>
          </div>
          <div class="mt-auto pt-6 flex justify-end">
            <router-link
              :to="'/blog/' + post.Slug"
              class="rounded-full bg-primary-focus text-base-content py-1 px-4 shadow-xl"
              >Read More</router-link
            >
          </div>
        </div>
      </div>
    </div> -->
    <div class="blog-posts-grid">
      <BlogCard
        v-for="post in paginatedItems"
        :key="post.ID"
        :post="post"
        :isBookmarked="isBookmarked(post.ID_Int)"
        :isLoggedIn="isLoggedIn"
      />
    </div>

    <!-- Pagination Controls -->
    <div class="flex justify-center items-center mt-8">
      <button
        @click="changePage(1)"
        :disabled="currentPage === 1"
        class="hidden md:block mx-1 px-3 py-1 rounded bg-base-100 text-base-content hover:bg-primary-focus disabled:opacity-50"
      >
        First
      </button>
      <button
        @click="changePage(currentPage - 1)"
        :disabled="currentPage === 1"
        class="mx-1 px-3 py-1 rounded bg-base-100 text-base-content hover:bg-primary-focus disabled:opacity-50"
      >
        Prev
      </button>
      <span class="mx-2 px-3 py-1 rounded bg-primary text-base-content">
        {{ currentPage }}
      </span>
      <button
        @click="changePage(currentPage + 1)"
        :disabled="currentPage === totalPages"
        class="mx-1 px-3 py-1 rounded bg-base-100 text-base-content hover:bg-primary-focus disabled:opacity-50"
      >
        Next
      </button>
      <button
        @click="changePage(totalPages)"
        :disabled="currentPage === totalPages"
        class="hidden md:block mx-1 px-3 py-1 rounded bg-base-100 text-base-content hover:bg-primary-focus disabled:opacity-50"
      >
        Last
      </button>
    </div>
  </div>
</template>

<style scoped>
.active-class {
  @apply bg-primary text-base-content;
}
.inactive-class {
  @apply bg-base-100 text-base-content;
}
.category-select-button {
  @apply h-8 w-32 py-1 px-2 rounded-full m-1 hover:border-secondary;
  font-family: Lato-Bold;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.knowledge-level-button {
  @apply h-8 w-32 py-1 px-2 rounded-full m-1 text-sm;
  font-family: Lato-Bold;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.blog-posts-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 1024px) {
  .blog-posts-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* .blog-post-card {
  max-width: 100%;
  display: flex;
  flex-direction: column;
} */
</style>
